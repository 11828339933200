import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getUser } from "../store/userSlice";
import { useAppDispatch } from "./useRedux";

const selectUserSelector = createSelector(
  (state: RootState) => state.users.users,
  (_: RootState, id: number) => id,
  (users, id) => users.find(user => user.id === id)
)

export default function useUser(id: number) {
  const dispatch = useAppDispatch()
  const user = useSelector((state: RootState) => selectUserSelector(state, id))

  useEffect(() => {
    if (id) {
      dispatch(getUser(id))
    }
  }, [id, dispatch])

  return user
}
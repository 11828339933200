import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkAPI } from ".";
import ApiService from "../api/api";
import { Tqi } from "../types/tqi";
import { dateToString } from "../utils";

type State = {
  loading: Boolean
  lastUpdated?: number
  tqis: Tqi[]
}

const initialState: State = {
  loading: false,
  tqis: [],
}

export const listTqis = createAsyncThunk<Tqi[], string | number, ThunkAPI>(
  'tqis/list',
  async (id, thunkAPI) => {
    const { centres } = thunkAPI.getState()
    const month = centres.month
    return (await ApiService.getTqis(id, dateToString(month ?? (new Date()).valueOf()))).data.data
  },
  {
    condition: (_, thunkAPI) => {
      return !thunkAPI.getState().tqi.loading
    }
  }
)

export const tqiSlice = createSlice({
  name: 'tqis',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listTqis.pending, (state, _) => {
      state.loading = true
    })
    builder.addCase(listTqis.fulfilled, (state, action) => {
      state.tqis = action.payload
      state.lastUpdated = Date.now()
      state.loading = false
    })
    builder.addCase(listTqis.rejected, (state, _) => {
      state.loading = false
    })
  }
})

// export const {  } = tqiSlice.actions

export default tqiSlice.reducer

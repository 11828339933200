import { useEffect, useState } from "react"
import { submitQualityControlData } from "../../api/audit"
import { deleteFile, downloadFile, getFilesByType } from "../../api/files"
import useAuth from "../../hooks/useAuth"
import { useNotification } from "../../hooks/useNotification"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { getQualityControlChecks } from "../../store/auditSlice"
import { QualityControlCheck } from "../../types/audit"
import { Centre } from "../../types/centre"
import { File } from "../../types/file"
import { classNames, dateToMonth, dateToString, formatTimestampString } from "../../utils"
import FileImport from "../FileImport"
import ImportModal from "../ImportModal"
import Switch from "../Switch"

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

type Props = {
  centre: Centre
  checks: Partial<QualityControlCheck>[]
  setChecks: React.Dispatch<React.SetStateAction<Partial<QualityControlCheck>[]>>
  deleteChecks: (string | number)[]
  setDeleteChecks: React.Dispatch<React.SetStateAction<(string | number)[]>>
}

export default function QualityControl({ centre, checks, setChecks, deleteChecks, setDeleteChecks }: Props) {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const month = useAppSelector(state => state.centres.month)
  const monthString = dateToString(month ?? (new Date()).valueOf())
  const [updating, setUpdating] = useState(false)
  const [hasTesters, setHasTesters] = useState(true)
  const notification = useNotification()
  const [upload, setUpload] = useState<{ endpoint: string, params?: { key: string, value: any }[] }>()
  const [showUpload, setShowUpload] = useState(false)

  const [files, setFiles] = useState<File[]>([])
  useEffect(() => {
    if (MANAGEMENT_ENABLED) {
      return
    }

    getFilesByType(centre.id, 'quality-control').then(response => setFiles(response.data.data))
  }, [centre])

  useEffect(() => {
    setHasTesters((centre?.testers?.filter(t => t.tester_id).length || 0) > 0)
  }, [centre])

  const getCheck = (tester?: string) => checks.find(c => c.tester_id === tester)

  const checkComplete = (tester?: string) => {
    return !!getCheck(tester) || false
  }

  const setCheck = (checked: boolean, tester?: string) => {
    if (!tester) {
      return
    }

    if (checked) {
      setChecks(checks => (
        [
          ...checks,
          {
            centre_id: centre.id,
            tester_id: tester,
            check_completed_at: dateToMonth(month)
          }
        ]
      ))
    } else if (!checked) {
      const idx = checks.findIndex(c => c.tester_id === tester)
      if (idx < 0) return
      const checkToDelete = checks[idx]
      if (checkToDelete.id !== undefined) {
        setDeleteChecks(checks => [...checks, checkToDelete.id!])
      }
      const newChecks = [...checks]
      newChecks.splice(idx, 1)
      setChecks(newChecks)
    }
  }

  const getComment = (tester?: string) => (getCheck(tester)?.comments || '')

  const setComment = (tester?: string, comments?: string) => {
    setChecks(checks => {
      const idx = checks.findIndex(c => c.tester_id === tester)
      if (idx < 0) {
        return checks
      }
      const newChecks = [...checks]
      newChecks[idx] = { ...newChecks[idx], comments: comments }
      return newChecks
    })
  }

  const getFiles = (tester?: string) => (getCheck(tester)?.supporting_files)

  const uploadEvidence = (record: Partial<QualityControlCheck>) => {
    setUpload({
      endpoint: `/management/quality-control-checks/${record.id}/evidence`
    })
    setShowUpload(true);
  }

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    setUpdating(true)
    try {
      await submitQualityControlData(centre.id, checks, deleteChecks)
      dispatch(getQualityControlChecks({ centre: centre.id, month: monthString }))
      notification.showSuccess(
        'Quality Control Saved',
      )
    } catch (error: any) {
      notification.showError(
        'Unable to submit quality control checks',
        error?.response?.data?.message
      )
    } finally {
      setUpdating(false)
    }
  }

  if (!MANAGEMENT_ENABLED) {
    return (
      <section className="space-y-6">
        <FileImport id="quality-control" title="Quality Control Import" endpoint="/import/quality-control" centre={centre!.id} />
        <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 font-header">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  Created At
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {files.length > 0 ? files.map((file, fileIdx) => (
                <tr key={file.id} className={fileIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {file.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatTimestampString(file.created_at)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      type="button"
                      className="link"
                      onClick={() => downloadFile(file)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              )) : (
                <tr className="bg-white">
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-300" colSpan={4}>
                    No files.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    )
  }

  return (
    <section>
      <form onSubmit={submit}>
        <div className="space-y-6">
          {!hasTesters ? (
            <div className="flex justify-center items-center h-64">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Centre has no testers.
              </h2>
            </div>
          ) : (
            centre.testers?.filter(t => t.tester_id).map(tester => {
              if (!tester.tester_id || !tester.roles?.includes('tester')) {
                return <></>
              }

              const check = getCheck(tester.tester_id)
              const files = getFiles(tester.tester_id)

              return (
                <div key={tester.tester_id} className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="bg-white px-4 py-5 sm:p-6 border-b">
                    <div className="flex items-end justify-between">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        {tester.name}
                      </h2>
                      <p className="text-sm text-gray-500">
                        {tester.tester_id}
                      </p>
                    </div>
                  </div>

                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-1">
                        <Switch
                          label="QC Complete"
                          enabled={checkComplete(tester.tester_id)}
                          setEnabled={checked => setCheck(checked, tester.tester_id)}
                        />
                      </div>

                      <div className="col-span-3 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Comments/Observations
                        </label>
                        <div className="mt-1">
                          <textarea
                            rows={6}
                            className="input"
                            value={getComment(tester.tester_id)}
                            onChange={e => setComment(tester.tester_id, e.target.value)}
                            disabled={!checkComplete(tester.tester_id)}
                          />
                        </div>
                      </div>

                      {files && files.length > 0 && (
                        <div className="col-span-4">
                          <span className="block mb-1 text-sm font-medium text-gray-700">
                            Evidence
                          </span>
                          <div className="border rounded-md overflow-hidden shadow-sm">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50 font-header">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                  >
                                    Created At
                                  </th>
                                  <th scope="col" className="relative px-6 py-3">
                                    <span className="sr-only">Actions</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {files.map((file, fileIdx) => (
                                  <tr key={file.id} className={fileIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {file.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatTimestampString(file.created_at)}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                      <button
                                        type="button"
                                        className="link"
                                        onClick={() => downloadFile(file)}
                                      >
                                        View
                                      </button>
                                      <button
                                        type="button"
                                        className="ml-2 link link--destructive"
                                        onClick={async () => {
                                          await deleteFile(file.centre_id, file.id)
                                          dispatch(getQualityControlChecks({ centre: centre.id, month: monthString }))
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="px-4 py-3 bg-gray-50 flex justify-end sm:px-6 sm:rounded-b-md">
                    <button
                      type="button"
                      className={classNames(
                        "btn btn--tertiary btn--small",
                        !check?.id ? "invisible" : "",
                      )}
                      disabled={!check?.id}
                      onClick={() => tester && uploadEvidence(check!)}
                    >
                      Add Evidence
                    </button>
                  </div>
                </div>
              )
            })
          )}

          {user?.can('submit-centre-reports') && (
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center btn btn--primary"
                disabled={updating}
              >
                {updating ? 'Saving...' : 'Save'}
              </button>
            </div>
          )}
        </div>
      </form>

      {upload && (
        <ImportModal
          endpoint={upload.endpoint}
          centre={centre!.id}
          open={showUpload}
          setOpen={setShowUpload}
          uploadSuccessful={() => {
            dispatch(getQualityControlChecks({ centre: centre.id, month: monthString }))
          }}
        />
      )}
    </section>
  )
}
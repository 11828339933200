import { BadgeCheckIcon, DocumentReportIcon, DocumentTextIcon, TableIcon } from "@heroicons/react/solid";
import { range } from "lodash";
import { classNames } from "../utils";

type Props = {
  testers?: number
  ratings?: {
    green: number
    amber: number
    red: number
  },
  onClick?: () => void,
}

export default function CalibrationCard(props: Props) {
  return (
    <div
      onClick={props.onClick}
      className={classNames(
        "h-40 bg-white overflow-hidden shadow-md rounded-lg transition-shadow duration-300",
        props.onClick ? "cursor-pointer hover:shadow-xl" : ""
      )}
    >
      <div className="h-full flex flex-col p-4">
        <h5 className="mb-6 text-xl font-header">Equipment Calibration</h5>
        <div className="mt-auto flex flex-wrap">
          {range(0, props.ratings?.green).map(() => (
            <DocumentTextIcon className="w-10 h-10 text-green-600" />
          ))}
          {range(0, props.ratings?.amber).map(() => (
            <DocumentTextIcon className="w-10 h-10 text-yellow-600" />
          ))}
          {range(0, props.ratings?.red).map(() => (
            <DocumentTextIcon className="w-10 h-10 text-red-600" />
          ))}
        </div>
      </div>
    </div>
  )
}
import React, { useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router"
import useCentre from "../hooks/useCentre"
import useCentres from "../hooks/useCentres"
import useTitle from "../hooks/useTitle"
import useAuth from "../hooks/useAuth"
import Select, { Option } from "../components/Select"
import ApiService from "../api/api"
import { useDispatch } from "react-redux"
import { putCentre } from "../store/centreSlice"
import ConfirmationModal from "../components/ConfirmationModal"
import { useNotification } from "../hooks/useNotification"
import { ErrorBag } from "../types/errors"
import useBrands from "../hooks/useBrands"
import { XCircleIcon } from "@heroicons/react/outline"
import DatePicker from "../components/DatePicket"
import { stringToUnix, TIMESTAMP_STRING_FORMAT, unixToString } from "../utils"
import { default as ReactSelect } from 'react-select';


type ReactSelectOption = { value?: string | number, label: string }

const defaultParentOption = { label: 'No Parent' }
const defaultBrandOption = { label: 'No Franchise' }
const ragGreen = { id: 'green', label: 'Green' }

const ragOptions = [
  ragGreen,
  { id: 'amber', label: 'Amber' },
  { id: 'red', label: 'Red' },
  { id: 'white', label: 'White' },
]

export default function EditCentre(props: RouteComponentProps<{ id: string }>) {
  const history = useHistory()
  const dispatch = useDispatch()
  const notification = useNotification()
  const user = useAuth()
  const centres = useCentres()
  const brands = useBrands()
  const centre = useCentre(parseInt(props.match.params.id))
  useTitle(centre ? 'Edit Centre' : 'Create Centre')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState(centre?.name || '')
  const [siteNumber, setSiteNumber] = useState(centre?.site_number || '')
  const [brandOptions, setBrandOptions] = useState<ReactSelectOption[]>([])
  const [centreOptions, setCentreOptions] = useState<Option[]>([])
  const [parent, setParent] = useState<Option>(defaultParentOption)
  const [brand, setBrand] = useState<ReactSelectOption[]>()
  const [rag, setRag] = useState<Option>(ragGreen)
  const [lastVisited, setLastVisited] = useState(
    centre?.last_visited_at
      ? stringToUnix(centre.last_visited_at)
      : undefined
  )
  const [dvsaLastVisited, setDvsaLastVisited] = useState(
    centre?.dvsa_last_visited_at
      ? stringToUnix(centre.dvsa_last_visited_at)
      : undefined
  )
  const [errors, setErrors] = useState<ErrorBag>()
  const [ipAddresses, setIpAddresses] = useState<string[]>(centre?.ip_addresses || [])

  const createCentre = async () => {
    return await ApiService.createCentre({
      name: name,
      site_number: siteNumber,
      rag_rating: rag.id?.toString(),
      brand_id: brand ? brand.map(b => b.value) : null,
      parent_id: parent.id || null,
      ip_addresses: ipAddresses,
      last_visited_at: lastVisited ? unixToString(lastVisited, TIMESTAMP_STRING_FORMAT) : null,
      dvsa_last_visited_at: dvsaLastVisited ? unixToString(dvsaLastVisited, TIMESTAMP_STRING_FORMAT) : null,
    })
  }

  const updateCentre = async () => {
    return await ApiService.updateCentre(centre!.id, {
      name: name,
      site_number: siteNumber,
      rag_rating: rag.id?.toString(),
      brand_id: brand ? brand.map(b => b.value) : null,
      parent_id: parent.id || null,
      ip_addresses: ipAddresses,
      last_visited_at: lastVisited ? unixToString(lastVisited, TIMESTAMP_STRING_FORMAT) : null,
      dvsa_last_visited_at: dvsaLastVisited ? unixToString(dvsaLastVisited, TIMESTAMP_STRING_FORMAT) : null,
    })
  }

  const deleteCentre = async () => {
    setSaving(true)

    try {
      ApiService.deleteCentre(centre!.id)
      notification.showSuccess(
        'Successfully Deleted',
        `Centre ${name} has been successfully deleted.`
      )
      history.replace('/centres')
    } catch (error: any) {
      notification.showError(
        'Failed to Delete',
        `Centre ${name} could not be deleted at this time.`
      )
    } finally {
      setSaving(false)
    }
  }

  const saveCentre = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)
    setErrors(undefined)

    try {
      const response = centre ? await updateCentre() : await createCentre()

      notification.showSuccess(
        'Successfully Saved',
        `Centre ${name} has been successfully saved.`
      )

      dispatch(putCentre(response.data.data))

      if (response.status === 201) {
        history.replace(`/centres/${response.data.data.id}/edit`)
      }
    } catch (e: any) {
      setErrors(new ErrorBag(e.response?.data))
    } finally {
      setSaving(false)
    }
  }

  useEffect(() => {
    let brandOptions: ReactSelectOption[] = [] // [defaultBrandOption]
    brandOptions = brandOptions.concat(
      brands.map(brand => ({ value: brand.id, label: brand.name }))
    )

    setBrandOptions(brandOptions)
  }, [brands])

  useEffect(() => {
    let centreOptions = [defaultParentOption]
    centreOptions = centreOptions.concat(
      centres
        .filter(c => c.id !== centre?.id)
        .map(centre => ({ id: centre.id, label: centre.name, desc: centre.site_number }))
    )

    setCentreOptions(centreOptions)
  }, [centre, centres])

  useEffect(() => {
    if (centre) {
      const parent = centreOptions.find(o => o.id === centre.parent_id)
      if (parent) {
        setParent(parent)
      }

      setBrand(brandOptions.filter(o => centre.brand_id?.includes(o.value)))

      const rag = ragOptions.find(r => r.id === centre.rag_rating)
      if (rag) {
        setRag(rag)
      }
    }
  }, [centre, brandOptions, centreOptions])

  // TODO: if no centre found, route to or return 404 component.

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-700">Centre Details</h3>
              <h5 className="mt-1 text-sm text-gray-500">{`${centre?.name} (${centre?.site_number})`}</h5>
            </div>
          </div>

          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={saveCentre}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-t-md">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="name" className="label">
                        Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          className="input"
                          required
                        />
                      </div>
                      <small className="text-red-600">{errors?.get('name')}</small>
                    </div>

                    <div className="col-span-3 sm:col-span-1">
                      <label htmlFor="site-number" className="label">
                        Site Number
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="site-number"
                          id="site-number"
                          value={siteNumber}
                          onChange={e => setSiteNumber(e.target.value)}
                          className="input"
                          required
                        />
                      </div>
                      <small className="text-red-600">{errors?.get('site_number')}</small>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Last Auditor Visit
                      </label>
                      <div className="mt-1">
                        <DatePicker
                          date={lastVisited}
                          onChange={date => setLastVisited(date)}
                        />
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        DVSA Last Visit
                      </label>
                      <div className="mt-1">
                        <DatePicker
                          date={dvsaLastVisited}
                          onChange={date => setDvsaLastVisited(date)}
                        />
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-1">
                      <Select
                        label="RAG Rating"
                        options={ragOptions}
                        selected={rag}
                        setSelected={setRag}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      {/* <Select
                        label="Franchise Brand"
                        options={brandOptions}
                        selected={brand}
                        setSelected={setBrand}
                      /> */}
                      <label className="label">Franchise Brand</label>
                      <div className="mt-1">
                        <ReactSelect
                          isMulti
                          name="brand"
                          value={brand}
                          options={brandOptions}
                          theme={(theme) => {
                            return {
                              ...theme,
                              colors: {
                                ...theme.colors,
                                danger: "#FEE2E2",
                                dangerLight: "#B91C1C",
                                neutral0: "#FFFFFF",
                                neutral5: "#F8FAFC",
                                neutral10: "#F1F5F9",
                                neutral20: "#CBD5E1",
                                neutral30: "#CBD5E1",
                                neutral40: "#94A3B8",
                                neutral50: "#64748B",
                                neutral60: "#475569",
                                neutral70: "#334155",
                                neutral80: "#1E293B",
                                neutral90: "#0F172A",
                                primary: "#278AA8",
                                primary25: "#a9d0dc",
                                primary50: "#a9d0dc",
                                primary75: "#a9d0dc",
                              }
                            }
                          }}
                          className="react-select-el"
                          classNamePrefix="react-select"
                          onChange={(e) => {
                            const brands = e.map(b => b.value)
                            setBrand(brandOptions.filter(b => brands.includes(b.value)))
                          }}
                        />
                      </div>
                    </div>

                    {user?.can('assign-parent-centre') && (
                      <div className="col-span-3 sm:col-span-2">
                        <Select
                          label="Parent Site"
                          options={centreOptions}
                          selected={parent}
                          setSelected={setParent}
                        />
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="mb-2 flex justify-between items-end">
                      <label className="label">
                        IP Addresses
                      </label>
                      <button
                        type="button"
                        className="mt-2 ml-auto btn btn--secondary btn--small"
                        onClick={() => setIpAddresses(items => [...items, ''])}
                      >
                        Add IP Address
                      </button>
                    </div>
                    <div className="grid grid-cols-3 gap-3">
                      {ipAddresses.map((address, index) => (
                        <div key={index} className="col-span-3 sm:col-span-1 relative rounded-md shadow-sm">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="input capitalize"
                            value={address}
                            onChange={e => (
                              setIpAddresses(items => {
                                let newItems = [...items]
                                newItems[index] = e.target.value
                                return newItems
                              })
                            )}
                          />
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                            onClick={() => (
                              setIpAddresses(items => {
                                if (items.length <= 1) {
                                  return ['']
                                }

                                let newItems = [...items]
                                newItems.splice(index, 1)
                                return newItems
                              })
                            )}
                          >
                            <XCircleIcon className="h-5 w-5 text-gray-300 hover:text-tertiary-400" aria-hidden="true" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-md">
                  <button
                    type="button"
                    className="mr-4 inline-flex justify-center btn btn--tertiary"
                    disabled={saving}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  {centre && (
                    <button
                      type="button"
                      className="mr-4 inline-flex justify-center btn btn--destructive"
                      disabled={saving}
                      onClick={() => setConfirmOpen(true)}
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center btn btn--primary"
                    disabled={saving}
                  >
                    {saving ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ConfirmationModal
        title={centre?.name}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteCentre}
      />
    </>
  )
}

import { useEffect } from "react";
import { listRoles } from "../store/roleSlice";
import { Role } from "../types/role";
import { useAppDispatch, useAppSelector } from "./useRedux";

export default function useRoles(search?: string) {
  const dispatch = useAppDispatch()
  const roles: Role[] = useAppSelector(state => state.roles.roles)

  useEffect(() => { dispatch(listRoles(search)) }, [dispatch, search])

  return roles
}
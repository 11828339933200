import { useAppDispatch } from "../hooks/useRedux"
import AuthService from '../api/auth'
import { getAuthenticatedUser, setAuthenticated } from "../store/authSlice"
import { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useNotification } from "../hooks/useNotification"

export default function ResetPassword() {
  const history = useHistory()
  const search = useLocation().search
  const notification = useNotification()
  const [submitting, setSubmitting] = useState(false)
  const [token, setToken] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')

  useEffect(() => {
    const query = new URLSearchParams(search)
    setToken(query.get('token') ?? '')
  }, [search])

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    setSubmitting(true)
    try {
      await AuthService.resetPassword(token, email, password, passwordConfirmation)
      history.replace('/')
    } catch (error: any) {
      notification.showError(
        'Unable to reset password',
        error?.response?.data?.message
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          /> */}
          <h2 className="mt-12 text-center text-3xl font-header font-bold text-gray-900">Reset Password</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={submit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-600">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password-confirmation" className="block text-sm font-medium text-gray-600">
                  Password Confirmation
                </label>
                <div className="mt-1">
                  <input
                    id="password-confirmation"
                    name="password-confirmation"
                    type="password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                    value={passwordConfirmation}
                    onChange={e => setPasswordConfirmation(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end">
                <div className="text-sm">
                  <Link to="/" className="link">Back to Login</Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="btn btn--primary w-full justify-center"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

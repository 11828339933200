import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkAPI } from ".";
import { Brand } from "../types/brand";
import ApiService from "../api/api";
import { differenceInHours } from "date-fns";

type State = {
  loading: Boolean
  lastUpdated?: number
  brands: Brand[]
}

const initialState: State = {
  loading: false,
  brands: [],
}

export const listBrands = createAsyncThunk<Brand[], string | undefined, ThunkAPI>(
  'brands/list',
  async (search, thunkAPI) => {
    return (await ApiService.listBrands(search)).data.data
  },
  {
    condition: (_, thunkAPI) => {
      // FIXME: swap this back, always fetching while working on forms
      // however, don't break search!

      // const { brands } = thunkAPI.getState()

      // return !brands.loading && (
      //   brands.lastUpdated &&
      //   differenceInHours(Date.now(), brands.lastUpdated) >= 1
      // )

      return true
    }
  }
)

export const getBrand = createAsyncThunk<Brand, number, ThunkAPI>(
  'brands/get',
  async (id, thunkAPI) => {
    return (await ApiService.getBrand(id)).data.data
  }
)

export const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    putBrand: (state, action: PayloadAction<Brand>) => {
      const idx = state.brands.findIndex(c => c.id === action.payload.id)
      const brand = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.brands[idx] = brand
      } else {
        state.brands.push(brand)
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(listBrands.pending, (state, _) => {
      state.loading = true
    })
    builder.addCase(listBrands.fulfilled, (state, action) => {
      state.brands = action.payload
      state.lastUpdated = Date.now()
      state.loading = false
    })
    builder.addCase(listBrands.rejected, (state, _) => {
      state.loading = false
    })
    builder.addCase(getBrand.fulfilled, (state, action) => {
      const idx = state.brands.findIndex(c => c.id === action.payload.id)
      const brand = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.brands[idx] = brand
      } else {
        state.brands.push(brand)
      }
    })
  }
})

export const { putBrand } = brandSlice.actions

export default brandSlice.reducer

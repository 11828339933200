import React, { useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router"
import useRoles from "../hooks/useRoles"
import useTitle from "../hooks/useTitle"
import useAuth from "../hooks/useAuth"
import Select, { Option } from "../components/Select"
import ApiService from "../api/api"
import { useDispatch } from "react-redux"
import { listPermissions, putRole } from "../store/roleSlice"
import ConfirmationModal from "../components/ConfirmationModal"
import { useNotification } from "../hooks/useNotification"
import { ErrorBag } from "../types/errors"
import useRole from "../hooks/useRole"
import usePermissions from "../hooks/usePermissions"
import Switch from "../components/Switch"

export default function EditRole(props: RouteComponentProps<{ id: string }>) {
  const history = useHistory()
  const dispatch = useDispatch()
  const notification = useNotification()
  const permissions = usePermissions()
  const role = useRole(parseInt(props.match.params.id))
  useTitle(role ? 'Edit Role' : 'Create Role')

  // TODO: if no role found, route to or return 404 component.

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState(role?.name || '')
  const [rolePermissions, setRolePermissions] = useState<string[]>(role?.permissions || [])
  const [errors, setErrors] = useState<ErrorBag>()

  useEffect(() => {
    setRolePermissions(role?.permissions || [])
  }, [role])

  const payload = () => ({ name, permissions: rolePermissions })

  const createRole = async () => {
    const response = await ApiService.createRole(payload())
    dispatch(listPermissions(true))

    return response
  }

  const updateRole = async () => {
    return await ApiService.updateRole(role!.id, payload())
  }

  const deleteRole = async () => {
    setSaving(true)

    try {
      ApiService.deleteRole(role!.id)
      notification.showSuccess(
        'Successfully Deleted',
        `Role ${name} has been successfully deleted.`
      )
      history.replace('/roles')
    } catch (error: any) {
      notification.showError(
        'Failed to Delete',
        `Role ${name} could not be deleted at this time.`
      )
    } finally {
      setSaving(false)
    }
  }

  const saveRole = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)
    setErrors(undefined)

    try {
      const response = role ? await updateRole() : await createRole()

      notification.showSuccess(
        'Successfully Saved',
        `Role ${name} has been successfully saved.`
      )

      dispatch(putRole(response.data.data))

      if (response.status === 201) {
        history.replace(`/roles/${response.data.data.id}/edit`)
      }
    } catch (e: any) {
      setErrors(new ErrorBag(e.response.data))
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-700 capitalize">{role?.name.replaceAll('-', ' ')}</h3>
              {/* <h5 className="mt-1 text-sm text-gray-500">{role?.site_number}</h5> */}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={saveRole}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-t-md">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="name" className="label">
                        Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          className="input"
                          required
                          disabled={role !== undefined}
                        />
                      </div>
                      <small className="text-red-600">{errors?.get('name')}</small>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="name" className="label">
                      Permissions
                    </label>
                    <div className="mt-2 grid grid-cols-2 gap-6">
                      {permissions.map(permission => {
                        return (
                          <div className="col-span-2 sm:col-span-1 capitalize">
                            <Switch
                              label={permission.name.replaceAll('-', ' ')}
                              enabled={rolePermissions.includes(permission.name)}
                              setEnabled={checked => {
                                if (checked) {
                                  setRolePermissions(rolePermissions => {
                                    const newPermission = [...rolePermissions]
                                    newPermission.push(permission.name)
                                    return newPermission
                                  })
                                } else {
                                  setRolePermissions(rolePermissions => {
                                    const newPermission = [...rolePermissions]
                                    newPermission.splice(newPermission.indexOf(permission.name), 1)
                                    return newPermission
                                  })
                                }
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-md">
                  <button
                    type="button"
                    className="mr-4 inline-flex justify-center btn btn--tertiary"
                    disabled={saving}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  {role && (
                    <button
                      type="button"
                      className="mr-4 inline-flex justify-center btn btn--destructive"
                      disabled={saving}
                      // onClick={deleteRole}
                      onClick={() => setConfirmOpen(true)}
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center btn btn--primary"
                    disabled={saving}
                  >
                    {saving ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ConfirmationModal
        title={role?.name}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteRole}
      />
    </>
  )
}

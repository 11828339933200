import { EditorContent, useEditor, Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from '@tiptap/extension-placeholder';
import { classNames } from "../utils";

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null
  }

  const buttons = [
    { key: 'bold', action: () => editor.chain().focus().toggleBold().run() },
    { key: 'italic', action: () => editor.chain().focus().toggleItalic().run() },
    { key: 'paragraph', action: () => editor.chain().focus().setParagraph().run() },
    { key: 'bulletList', name: 'bullet list', action: () => editor.chain().focus().toggleBulletList().run() },
    { key: 'orderedList', name: 'ordered list', action: () => editor.chain().focus().toggleOrderedList().run() },
    // { key: 'blockquote', action: () => editor.chain().focus().setBlockquote().run() },
  ]

  return (
    <div className="flex flex-wrap -mx-1">
      {buttons.map(button => (
        <button
          type="button"
          key={button.key}
          onClick={button.action}
          className={classNames(
            'mx-1 capitalize btn btn--small',
            editor.isActive(button.key) ? 'btn--secondary' : 'btn--tertiary'
          )}
        >
          {button.name ?? button.key}
        </button>
      ))}
    </div>
  )
}

type Props = {
  content: string
  onChange: (content: string) => void
  className?: string
}

export default function TipTap(props: Props) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Write your report ...'
      }),
    ],
    editorProps: {
      attributes: {
        class: 'w-full prose prose-xs sm:prose-sm focus:outline-none',
      },
    },
    content: props.content,
    onUpdate: ({ editor }) => {
      props.onChange(editor.getHTML())
    }
  })

  return (
    <div className={classNames("border rounded-md shadow-sm", props.className)}>
      <div className="border-b p-4 bg-gray-50 rounded-t-md">
        <MenuBar editor={editor} />
      </div>
      <div className="p-4 bg-white rounded-b-md">
        <EditorContent editor={editor} />
      </div>
    </div>
  )
}
import { PlusIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { Link } from "react-router-dom"
import useCentres from "../hooks/useCentres"
import { useDebounce } from "../hooks/useDebounce"
import { useAppDispatch } from "../hooks/useRedux"
import useTitle from "../hooks/useTitle"
import { setCentre } from "../store/centreSlice"

export default function Centres() {
  useTitle('Centres')
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const centres = useCentres(debouncedSearch)

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

          <div className="mb-2 flex justify-end">
            <div className="mr-4 w-64">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="input"
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <Link
              to="/centres/create"
              className="btn btn--primary"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add
            </Link>
          </div>

          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 font-header">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Site Number
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {centres.length > 0 ? centres.map((centre, centreIdx) => (
                  <tr key={centre.id} className={centreIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{centre.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{centre.site_number}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {!centre.isParent && <Link
                          to={`/centres/${centre.id}`}
                          onClick={() => dispatch(setCentre(centre.id))}
                          className="mr-4 link">
                          Dashboard
                        </Link>
                      }
                      <Link
                        to={`/centres/${centre.id}/capture`}
                        onClick={() => dispatch(setCentre(centre.id))}
                        className="mr-4 link">
                        Reporting
                      </Link>
                      <Link
                        to={`/centres/${centre.id}/edit`}
                        onClick={() => dispatch(setCentre(centre.id))}
                        className="link">
                        Edit
                      </Link>
                    </td>
                  </tr>
                )) : (
                  <tr className="bg-white">
                    <td colSpan={3} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">You have no Centres assigned.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

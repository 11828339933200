import { format, formatISO, fromUnixTime, getUnixTime, parse, parseISO, startOfMonth } from "date-fns"

export const TIMESTAMP_STRING_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const DATE_FORMAT = 'yyyy-MM-dd'
export const DATE_STRING_FORMAT = 'dd/MM/yyyy'
export const DATETIME_STRING_FORMAT = 'dd/MM/yyyy HH:mm'

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(' ')
}

export function stringToUnix(date: string, dateFormat: string = TIMESTAMP_STRING_FORMAT) {
  return getUnixTime(parse(date, dateFormat, new Date()))
}

export function unixToString(date: number, dateFormat: string = TIMESTAMP_STRING_FORMAT) {
  return format(fromUnixTime(date), dateFormat)
}

export function dateToString(date: number | Date, dateFormat: string = DATE_FORMAT) {
  return format(date, dateFormat)
}

export function dateToMonth(date: number | Date) {
  return dateToString(startOfMonth(date))
}

export function stringToMonth(date: string, dateFormat: string = DATE_FORMAT) {
  return dateToMonth(parse(date, dateFormat, new Date()))
}

export function formatDateString(date: string, dateFormat: string = DATE_STRING_FORMAT) {
  return format(
    parse(date, TIMESTAMP_STRING_FORMAT, new Date()),
    dateFormat
  )
}

export function formatTimestampString(date: string, dateFormat: string = DATETIME_STRING_FORMAT) {
  return format(parseISO(date), dateFormat)
}

export const colours = [
  '#446f7c',
  '#154B5B',
  '#113c49',
  '#0d2d37',
  '#52a1b9',
  '#278AA8',
  '#1f6e86',
  '#175365',
  '#7aabb9',
  '#5996A8',
  '#477886',
  '#355a65',
];

export const randomColor = () => {
  const idx = Math.floor(Math.random() * colours.length)
  return colours[idx]
}
import { XIcon } from "@heroicons/react/outline"
import { endOfMonth, format, getUnixTime, startOfMonth } from "date-fns"
import { useEffect, useState } from "react"
import { start } from "repl"
import { apiClient } from "../api/api"
import { classNames, stringToUnix, unixToString } from "../utils"
import DatePicker from "./DatePicket"

const DATE_FORMAT = 'yyyy-MM-dd'

type Props = {
  id: string
  title?: string
  description?: string
  endpoint: string
  centre?: string | number
  date?: string
  params?: { key: string, value: any }[]
  fileType: string
  hasStartDateInput?: boolean
  hasEndDateInput?: boolean
}

export default function FileExport({ endpoint, centre, date, params, ...props }: Props) {
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [exporting, setExporting] = useState(false)

  const onExport = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (exporting) return;
    setExporting(true);
    let params = {
      'start_date': startDate,
      'end_date': endDate
    };

    try {
      const response = await apiClient.get(endpoint, {
        responseType: 'blob',
        params
      })
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      // const [, filename] = response.headers['content-disposition'].split('filename=');
      link.href = url;
      link.setAttribute('download', props.title+props.fileType); //or any other extension
      document.body.appendChild(link);
      link.click();
      setExporting(false);
    } catch (error) {
      setExporting(false);
    }
  };

  return (
    <form className="space-y-6 divide-y divide-gray-200" onSubmit={onExport}>
      <div className="shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-t-md">
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">{props.title || 'Export'}</h3>
                {props.description && (
                  <p className="mt-1 text-sm text-gray-500">
                    {props.description}
                  </p>
                )}
              </div>

              <div className="mt-4 grid grid-cols-8 gap-y-6 gap-x-4">
                {(props.hasStartDateInput && props.hasStartDateInput === true) && (
                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="startDate" className="label">
                      Start Date
                    </label>
                    <div className="mt-1">
                      <DatePicker
                        date={startDate ? stringToUnix(startDate, DATE_FORMAT) : getUnixTime(startOfMonth(new Date()))}
                        onChange={date => date && setStartDate(unixToString(date, DATE_FORMAT))}
                      />
                    </div>
                  </div>
                )}

                {(props.hasEndDateInput && props.hasEndDateInput === true) && (
                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="endDate" className="label">
                      End Date
                    </label>
                    <div className="mt-1">
                      <DatePicker
                        date={endDate ? stringToUnix(endDate, DATE_FORMAT) : getUnixTime(endOfMonth(new Date()))}
                        onChange={date => date && setEndDate(unixToString(date, DATE_FORMAT))}
                      />
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-md">
          <button
            type="submit"
            className="inline-flex justify-center btn btn--primary"
            disabled={exporting}
          >
            {exporting ? 'Exporting...' : 'Export'}
          </button>
        </div>
      </div>
    </form>
  )
}
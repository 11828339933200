import { Centre } from "../../types/centre"
import FileExport from "../FileExport"

export default function Exports({ centre }: { centre: Centre }) {

  return (
    <section className="gap-6 grid grid-cols-4">
      {centre && (
        <div className="col-span-4">
          <FileExport
            id="tqi"
            title="TQI Export"
            fileType=".xlsx"
            endpoint={`/export/tqi/${centre!.id}`}
            hasStartDateInput={true}
            hasEndDateInput={true}
            centre={centre!.id} />
        </div>
      )}
      {centre && (
        <div className="col-span-4 sm:col-span-2">
          <FileExport id="test-logs"
            title="Test Logs XLSX Export"
            fileType=".xlsx"
            endpoint={`/export/test-logs/${centre!.id}`}
            hasStartDateInput={true}
            hasEndDateInput={true}
            centre={centre!.id} />
        </div>
      )}
      {centre && (
        <div className="col-span-4 sm:col-span-2">
          <FileExport
            id="test-logs-pdf"
            title="Test Logs PDF Export"
            fileType=".pdf"
            endpoint={`/export/test-logs/${centre!.id}/pdf`}
            hasStartDateInput={true}
            hasEndDateInput={true}
            centre={centre!.id} />
        </div>
      )}
      {/* <div className="col-span-4">
        <FileExport
          id="master-report"
          title="Master Report"
          fileType=".xlsx"
          endpoint={`/export/master-report`}
          centre={centre!.id} />
      </div> */}
    </section>
  )
}
import axios from "axios";
import { store } from "../store";
import { logout } from "../store/authSlice";
import { CentrePayload } from "../types/centre";
import { Tqi } from "../types/tqi"

export const apiClient = axios.create({
  baseURL: (process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8008') + '/api',
  withCredentials: true,
})

apiClient.interceptors.response.use(
  response => response,
  error => {
    const { auth } = store.getState()

    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      auth.authenticated
    ) {
      store.dispatch(logout())
    }

    return Promise.reject(error)
  }
)

const actions = {
  // roles
  listPermissions() {
    return apiClient.get('/permissions')
  },
  listRoles(search?: string) {
    return apiClient.get('/roles', {
      params: { search }
    })
  },
  getRole(id: number) {
    return apiClient.get(`/roles/${id}`)
  },
  createRole(payload: { name: string, permissions: string[] }) {
    return apiClient.post('/roles', payload)
  },
  updateRole(
    id: number,
    payload: { name: string, permissions: string[] }
  ) {
    return apiClient.put(`/roles/${id}`, payload)
  },
  deleteRole(id: number) {
    return apiClient.delete(`/roles/${id}`)
  },

  // users
  listUsers(search?: string, role?: string | number) {
    return apiClient.get('/users', {
      params: { search, role }
    })
  },
  getUser(id: number) {
    return apiClient.get(`/users/${id}`)
  },
  createUser(payload: { name: string, email: string, tester_id?: string, centres: (string | number)[], roles: string[] }) {
    return apiClient.post('/users', payload)
  },
  updateUser(
    id: number,
    payload: { name: string, email: string, tester_id?: string, centres: (string | number)[], roles: string[] }
  ) {
    return apiClient.put(`/users/${id}`, payload)
  },
  deleteUser(id: number) {
    return apiClient.delete(`/users/${id}`)
  },

  // centres
  listCentres(search?: string) {
    return apiClient.get('/centres', {
      params: { search }
    })
  },
  getCentre(id: number) {
    return apiClient.get(`/centres/${id}`)
  },
  getCentreStats(id: string | number, month: string) {
    return apiClient.get(`/centres/${id}/stats`, {
      params: { month }
    })
  },
  createCentre(payload: CentrePayload) {
    return apiClient.post('/centres', payload)
  },
  updateCentre(id: number, payload: CentrePayload) {
    return apiClient.put(`/centres/${id}`, payload)
  },
  deleteCentre(id: number) {
    return apiClient.delete(`/centres/${id}`)
  },
  sendCentreReport(id: string | number, report: string, date: string, last_visited_at?: string | null) {
    return apiClient.post(`/centres/${id}/monthly-report/send`, { date, report, last_visited_at })
  },
  saveCentreReport(id: string | number, report: string, date: string, last_visited_at?: string | null) {
    return apiClient.post(`/centres/${id}/monthly-report`, { date, report, last_visited_at })
  },

  // brands
  listBrands(search?: string) {
    return apiClient.get('/brands', {
      params: { search }
    })
  },
  getBrand(id: number) {
    return apiClient.get(`/brands/${id}`)
  },
  createBrand(payload: { name: string }) {
    return apiClient.post('/brands', payload)
  },
  updateBrand(
    id: number,
    payload: { name: string }
  ) {
    return apiClient.put(`/brands/${id}`, payload)
  },
  deleteBrand(id: number) {
    return apiClient.delete(`/brands/${id}`)
  },

  // Settings
  getReport() {
    return apiClient.get('/settings/report')
  },
  saveReport(report: string) {
    return apiClient.post('/settings/report', { report })
  },

  // Tqis
  getTqis(centre: string | number, month: string) {
    return apiClient.get('/tqi', { params: { centre, month } })
  },
  saveTqi(payload: Partial<Tqi>) {
    return apiClient.post('/tqi', payload)
  },
}

export default actions

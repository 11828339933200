import { useState } from "react"
import { submitTqiCheckData } from "../../api/audit"
import useAuth from "../../hooks/useAuth"
import { useNotification } from "../../hooks/useNotification"
import { useAppSelector } from "../../hooks/useRedux"
import { TqiCheck } from "../../types/audit"
import { Centre } from "../../types/centre"
import { dateToMonth } from "../../utils"

type Props = {
  centre: Centre
  tqiChecks: Partial<TqiCheck>[]
  setTqiChecks: React.Dispatch<React.SetStateAction<Partial<TqiCheck>[]>>
}

export default function MonthlyChecks({ centre, tqiChecks, setTqiChecks }: Props) {
  const user = useAuth()
  const month = useAppSelector(state => state.centres.month)
  const [updating, setUpdating] = useState(false)
  const notification = useNotification()

  const getCheck = (check: string) => {
    return tqiChecks.find(t => t.check === check)?.comments || ''
  }

  const setCheck = (check: string, comments: string) => setTqiChecks(checks => {
    const idx = checks.findIndex(t => t.check === check)
    if (idx < 0) {
      return [...checks, { check, comments }]
    }

    const newChecks = [...checks]
    newChecks[idx] = { ...newChecks[idx], comments }

    return newChecks
  })

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    setUpdating(true)
    try {
      await submitTqiCheckData(centre.id, dateToMonth(month), tqiChecks)
      notification.showSuccess(
        'Successfully Saved'
      )
    } catch (error: any) {
      notification.showError(
        'Unable to submit monthly TQI checks',
        error?.response?.data?.message
      )
    } finally {
      setUpdating(false)
    }
  }

  return (
    <section key={month} className="space-y-6">
      <form onSubmit={submit}>
        <div className="space-y-6">
          <div className="shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6 border-b border-grey-50 sm:rounded-t-md">
              <div className="flex items-end justify-between">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Site Manager Monthly Checks
                </h2>
                {/* <p className="text-sm text-gray-500"></p> */}
              </div>
            </div>

            <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-b-md">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Test Log v Invoices
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      className="input"
                      value={getCheck('Test Log v Invoices')}
                      onChange={e => setCheck('Test Log v Invoices', e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Test Quality - Statistics Report
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      className="input"
                      value={getCheck('Test Quality - Statistics Report')}
                      onChange={e => setCheck('Test Quality - Statistics Report', e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Fail Rates - Information
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      className="input"
                      value={getCheck('Fail Rates - Information')}
                      onChange={e => setCheck('Fail Rates - Information', e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Appeals - Discussion
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      className="input"
                      value={getCheck('Appeals - Discussion')}
                      onChange={e => setCheck('Appeals - Discussion', e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Quality Control - Statistics per Tester
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      className="input"
                      value={getCheck('Quality Control - Statistics per Tester')}
                      onChange={e => setCheck('Quality Control - Statistics per Tester', e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    PRS - Statistics per Tester
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      className="input"
                      value={getCheck('PRS - Statistics per Tester')}
                      onChange={e => setCheck('PRS - Statistics per Tester', e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Special Notices - Discussion and signature for master copy
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      className="input"
                      value={getCheck('Special Notices - Discussion and signature for master copy')}
                      onChange={e => setCheck('Special Notices - Discussion and signature for master copy', e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {user?.can('submit-centre-reports') && (
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center btn btn--primary"
                disabled={updating}
              >
                {updating ? 'Saving...' : 'Save'}
              </button>
            </div>
          )}
        </div>
      </form>
    </section>
  )
}
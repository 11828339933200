import { useCallback, useEffect, useState } from "react"
import { deleteFile, downloadFile } from "../api/files"
import useAuth from "../hooks/useAuth"
import { useDebounce } from "../hooks/useDebounce"
import { useNotification } from "../hooks/useNotification"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import { listFiles } from "../store/fileSlice"
import { Centre } from "../types/centre"
import { File } from "../types/file"
import { formatTimestampString } from "../utils"
import ConfirmationModal from "./ConfirmationModal"
import ImportModal from "./ImportModal"

export default function FileRepository({ centre }: { centre: Centre }) {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const notification = useNotification()
  const files = useAppSelector(state => state.files.files)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const [open, setOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [currentFile, setCurrentFile] = useState<File>()

  const dispatchListFiles = useCallback(() => {
    dispatch(listFiles({ centre: centre.id, search: debouncedSearch }))
  }, [centre.id, debouncedSearch, dispatch])

  const dispatchDeleteFile = useCallback(async (fileId) => {
    setLoading(true)
    try {
      await deleteFile(centre.id, fileId)
      dispatchListFiles()
      notification.showSuccess('File Deleted');
    } finally {
      setLoading(false)
    }
  }, [centre.id, dispatchListFiles, notification])

  useEffect(() => dispatchListFiles(), [dispatchListFiles])

  return (
    <div>
      <div className="mb-2 flex justify-end">
        <div className="mr-4 w-64">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="input"
            placeholder="Search"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
        <button
          className="btn btn--primary"
          onClick={() => setOpen(true)}
        >
          Upload
        </button>
      </div>

      <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 font-header">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                Created At
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {files.length > 0 ? files.map((file, fileIdx) => (
              <tr key={file.id} className={fileIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {file.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatTimestampString(file.created_at)}</td>
                {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{file.created_at}</td> */}
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    type="button"
                    className="link"
                    onClick={() => downloadFile(file)}
                  >
                    View
                  </button>
                  {user?.can('delete-centres') && (
                    <button
                      className="ml-2 link link--destructive"
                      onClick={() => {
                        // dispatchDeleteFile(file.id)
                        setCurrentFile(file)
                        setConfirmOpen(true)
                      }}
                      disabled={loading}
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            )) : (
              <tr className="bg-white">
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-300" colSpan={4}>
                  No files.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <ImportModal
        endpoint={`/centres/${centre.id}/files`}
        centre={centre.id}
        open={open}
        setOpen={setOpen}
        uploadSuccessful={() => dispatchListFiles()}
        cancelled={failed => {
          if (failed) {
            notification.showError(
              'Failed to import file.',
              'We could not upload the file at this time, please try again later.'
            )
          }
        }}
        hasNameInput
      />

      <ConfirmationModal
        title={currentFile?.name}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => {
          if (currentFile) {
            dispatchDeleteFile(currentFile.id)
          }
        }}
      />
    </div>
  )
}
import { getUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import api from "../../api/api";
import { useNotification } from "../../hooks/useNotification";
import { useAppSelector } from "../../hooks/useRedux";
import { Centre } from "../../types/centre";
import { dateToString, stringToUnix, TIMESTAMP_STRING_FORMAT, unixToString } from "../../utils";
import DatePicker from "../DatePicket";
import TipTap from "../TipTap";

type Props = {
  centre: Centre
}

export default function MonthlyReport({ centre }: Props) {
  const notification = useNotification()
  const month = useAppSelector(state => state.centres.month)
  const [lastVisited, setLastVisited] = useState(
    centre.last_visited_at
      ? stringToUnix(centre.last_visited_at)
      : undefined
  )
  const [report, setReport] = useState(centre.report ?? '')
  const [updating, setUpdating] = useState(false)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setReport(centre.report ?? '')
  }, [centre.report])

  const saveReport = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)

    try {
      await api.saveCentreReport(
        centre.id,
        report,
        dateToString(month ?? (new Date()).valueOf()),
        lastVisited ? unixToString(lastVisited, TIMESTAMP_STRING_FORMAT) : null
      )

      notification.showSuccess('Report successfully saved')
    } catch (error: any) {
      let message
      if (error?.response?.data?.errors?.date?.length) {
        message = error?.response?.data?.errors?.date[0]
      } else if (error?.response?.data?.errors?.report?.length) {
        message = error?.response?.data?.errors?.report[0]
      } else {
        message = error?.response?.data?.message || ''
      }

      notification.showError('Failed to Save Report', message)
    } finally {
      setSaving(false)
    }
  }
  const sendReport = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setUpdating(true)

    try {
      await api.sendCentreReport(
        centre.id,
        report,
        dateToString(month ?? (new Date()).valueOf()),
        lastVisited ? unixToString(lastVisited, TIMESTAMP_STRING_FORMAT) : null
      )

      notification.showSuccess('Report successfully sent', 'Monthly report has been sent to the site\'s managers')
    } catch (error: any) {
      let message
      if (error?.response?.data?.errors?.date?.length) {
        message = error?.response?.data?.errors?.date[0]
      } else if (error?.response?.data?.errors?.report?.length) {
        message = error?.response?.data?.errors?.report[0]
      } else {
        message = error?.response?.data?.message || ''
      }

      notification.showError('Failed to Send Report', message)
    } finally {
      setUpdating(false)
    }
  }

  return (
    <section>
      <form>
        <div className="space-y-6">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white px-4 py-5 sm:p-6 border-b">
              <div className="flex items-end justify-between">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  {month?dateToString(month, 'MMMM yyyy'):''} Monthly Report
                </h2>
                {/* <p className="text-sm text-gray-500"></p> */}
              </div>
            </div>
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Date of Last Visit
                  </label>
                  <div className="mt-1">
                    <DatePicker
                      date={lastVisited}
                      onChange={date => setLastVisited(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <TipTap content={report} onChange={setReport} />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="inline-flex justify-center btn btn--primary"
              disabled={updating || saving}
              onClick={saveReport}
            >
              {saving ? 'Saving...' : 'Save'}
            </button>
            <button
              type="submit"
              className="ml-4 inline-flex justify-center btn btn--primary"
              disabled={updating || saving}
              onClick={sendReport}
            >
              {updating ? 'Sending...' : 'Save & Send'}
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}
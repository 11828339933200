import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  BrowserRouter as Router, Route, Switch,
} from "react-router-dom";
import Login from "./pages/Login";
import Notification, { NotificationContext } from "./components/Notification";
import Shell from "./pages/Shell";
import { TitleContext } from "./context/TitleContext";
import { useNotificationProvider } from "./hooks/useNotification";
import { useAppDispatch, useAppSelector } from "./hooks/useRedux";
import { getAuthenticatedUser } from "./store/authSlice";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

Sentry.init({
  dsn: "https://e520579a10c7470da969c0f6f4069873@o480027.ingest.sentry.io/6161685",
});

function App() {
  const dispatch = useAppDispatch()
  const authenticated = useAppSelector(state => state.auth.authenticated)
  const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
  const [title, setTitle] = useState<string>()
  const [subtitle, setSubtitle] = useState<string>()
  const [showMonth, setShowMonth] = useState(false)
  const [showCentre, setShowCentre] = useState(false)
  const [notificationOptions, triggerNotification] = useNotificationProvider()

  useEffect(() => {
    if (!authenticatedUser) {
      dispatch(getAuthenticatedUser())
    }
  }, [dispatch, authenticatedUser])

  return (
    <NotificationContext.Provider value={{ showNotification: triggerNotification }}>
    <TitleContext.Provider value={{ title, subtitle, showMonth, showCentre, setTitle, setSubtitle, setShowMonth, setShowCentre }}>
      <Router>
        {authenticated ? (
          <Shell />
        ) : (
          <Switch>
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route component={Login} />
          </Switch>
        )}
      </Router>
      <Notification {...notificationOptions} />
    </TitleContext.Provider>
    </NotificationContext.Provider>
  );
}

export default App;

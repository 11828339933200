import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"
import { PlusIcon } from "@heroicons/react/solid"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import { listTqis } from "../store/tqiSlice"
import { Centre } from "../types/centre"
import { Tqi } from "../types/tqi"
import { classNames } from "../utils"

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

type Props = {
  centre: Centre
  allowAdd?: boolean
  showFull?: boolean
}

export default function Tqis({ centre, allowAdd = true, showFull = false }: Props) {
  const dispatch = useAppDispatch()
  const data = useAppSelector(state => state.tqi.tqis)
  const [tqis, setTqis] = useState<Tqi[]>([])
  const currentSort = useRef<string>()

  useEffect(() => {
    dispatch(listTqis(centre.id))
  }, [dispatch, centre.id])

  useEffect(() => {
    if (data) {
      setTqis(data)
    } else {
      setTqis([])
    }
  }, [data])

  const sort = (col: string) => {
    const desc = currentSort.current === col

    setTqis(data => {
      const sorted = [...data]
      sorted.sort((a: any, b: any) => {
        const exempt = ["site", "national"];
        if (exempt.includes(a.tester_id)) {
          if (a.tester_id === "site" && b.tester_id === "national") {
            return -1;
          }
          return 1;
        }
        const colA = parseFloat(a[col])
        const colB = parseFloat(b[col])

        return colA === colB ? 0 : (
          desc ? (colA > colB ? -1 : 1) : (colA < colB ? -1 : 1)
        )
      })
      return sorted
    })

    currentSort.current = desc ? `${col}-desc` : col
  }

  return (
    <section>
      <div>
        <div className="mb-2 flex justify-end">
          {(MANAGEMENT_ENABLED && allowAdd) && (
            <Link
              to={`/centres/${centre.id}/tqi`}
              className="inline-flex justify-center btn btn--secondary"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add
            </Link>
          )}
        </div>
        <div className="-mx-1 sm:overflow-x-auto">
          <div className="px-1 pb-1 align-middle inline-block min-w-full">
            <div className="shadow border-b border-gray-200 sm:overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 font-header">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Tester ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div className="flex items-center">
                        Tests
                        <button
                          type="button"
                          className="ml-2"
                          onClick={() => sort('tests_done')}
                        >
                          <ChevronDownIcon
                            className={classNames(
                              'w-4 h-4 transition-transform',
                              currentSort.current?.startsWith('tests_done') ? 'text-secondary-500' : 'text-gray-300',
                              currentSort.current === 'tests_done-desc' ? 'transform -rotate-180' : ''
                            )}
                          />
                        </button>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div className="flex items-center">
                        Avg. Vehicle Age
                        <button
                          type="button"
                          className="ml-2"
                          onClick={() => sort('avg_veh_age')}
                        >
                          <ChevronDownIcon
                            className={classNames(
                              'w-4 h-4 transition-transform',
                              currentSort.current?.startsWith('avg_veh_age') ? 'text-secondary-500' : 'text-gray-300',
                              currentSort.current === 'avg_veh_age-desc' ? 'transform -rotate-180' : ''
                            )}
                          />
                        </button>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div className="flex items-center">
                        Avg. Test Time
                        <button
                          type="button"
                          className="ml-2"
                          onClick={() => sort('avg_test_time')}
                        >
                          <ChevronDownIcon
                            className={classNames(
                              'w-4 h-4 transition-transform',
                              currentSort.current?.startsWith('avg_test_time') ? 'text-secondary-500' : 'text-gray-300',
                              currentSort.current === 'avg_test_time-desc' ? 'transform -rotate-180' : '',
                            )}
                          />
                        </button>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div className="flex items-center">
                        Avg. Fail
                        <button
                          type="button"
                          className="ml-2"
                          onClick={() => sort('avg_fail_percentage')}
                        >
                          <ChevronDownIcon
                            className={classNames(
                              'w-4 h-4 transition-transform',
                              currentSort.current?.startsWith('avg_fail_percentage') ? 'text-secondary-500' : 'text-gray-300',
                              currentSort.current === 'avg_fail_percentage-desc' ? 'transform -rotate-180' : '',
                            )}
                          />
                        </button>
                      </div>
                    </th>
                    {showFull && (
                      <>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail body
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail brakes
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail buses
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail veh id
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail lamps
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail noise
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail road wheels
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail seatbelt installation
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail seatbelt supplementary
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail speedometer
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail steering
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail suspension
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail tyres
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          fail visibility
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {tqis.length > 0 ? (
                    tqis.map((tqi, tqiIdx) => (
                      <tr key={tqi.id} className={tqiIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 capitalize">
                          {tqi.tester_id}
                          <small className="block text-xs font-base text-gray-500">{tqi.tester?.name}</small>
                          {/* <small className="block text-xs font-base text-gray-500">{String(tqi.id).padStart(7, '0')}</small> */}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.tests_done}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.avg_veh_age}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.avg_test_time}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.avg_fail_percentage}</td>
                        {showFull && (
                          <>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_body}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_brakes}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_buses}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_veh_id}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_lamps}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_noise}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_road_wheels}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_seatbelt_installation}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_seatbelt_supplementary}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_speedometer}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_steering}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_suspension}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_tyres}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{tqi.fail_visibility}</td>
                          </>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-white">
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-300" colSpan={showFull ? 22 : 8}>
                        No tqi data.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
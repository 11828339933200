import { Link } from "react-router-dom";
import { Centre } from "../types/centre";
import { classNames } from "../utils";

export default function Testers({ centre }: { centre: Centre }) {
  return (
    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 font-header">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Tester ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Roles
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {(centre.testers && centre.testers.length > 0 && centre.testers?.some(t => t.roles?.some(r => r === 'site-manager' || r === 'tester'))) ? centre.testers.map((user, userIdx) => {
            return (user.roles?.some(r => r === 'site-manager' || r === 'tester') &&
              <tr key={user.id} className={userIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.tester_id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user.roles?.map((role, roleIdx) => (
                    <span
                      key={role}
                      className={classNames(
                        'inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-secondary-400 text-white capitalize',
                        roleIdx > 0 ? 'ml-2' : ''
                      )}
                    >
                      {role.replace('-', ' ')}
                    </span>
                  ))}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <Link to={`/users/${user.id}/edit`} className="link">
                    Edit
                  </Link>
                </td>
              </tr>
            )
          }
          ) : (
            <tr className="bg-white">
              <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">You have no Users assigned.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
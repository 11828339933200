import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import FileImport from './FileImport'

type ImportModalProps = {
  endpoint: string
  centre?: string | number
  date?: string
  params?: { key: string, value: any }[]
  open: boolean
  setOpen: (open: boolean) => void
  uploadSuccessful?: () => void
  cancelled?: (failed: boolean) => void
  hasNameInput?: boolean
}

export default function ImportModal({ endpoint, centre, date, params, open, setOpen, ...props }: ImportModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
              <FileImport
                id="import-modal"
                title="Upload"
                endpoint={endpoint}
                centre={centre}
                date={date}
                params={params}
                uploadSuccessful={() => {
                  setOpen(false)
                  if (props.uploadSuccessful) {
                    props.uploadSuccessful()
                  }
                }}
                cancelled={failed => {
                  setOpen(false)
                  if (props.cancelled) {
                    props.cancelled(failed)
                  }
                }}
                hasNameInput={props.hasNameInput}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import DayPickerInput from 'react-day-picker/DayPickerInput';
import slugify from "slugify";
import {
  parse as dateFnsParse,
  format as dateFnsPormat,
  fromUnixTime,
  getUnixTime,
  startOfDay,
} from 'date-fns';
import { DateUtils, DayPickerProps } from 'react-day-picker';
import { XIcon } from '@heroicons/react/outline';
import { classNames } from '../utils';

const FORMAT = "dd/MM/yyyy"

const parseDate = (str: string, format: string) => {
  if (str.length < 10) return undefined

  const parsed = dateFnsParse(str, format, new Date())

  return DateUtils.isDate(parsed) ? parsed : undefined
}

const formatDate = (date: Date, format: string) => {
  return dateFnsPormat(date, format)
}

export type InputProps = {
  label?: string
  hint?: string
  date?: number
  onChange: (date?: number) => void
  onClear?: () => void
  error?: string
  min?: number
  max?: number
  pin?: 'top' | 'bottom'
  disableWeekends?: boolean
  dayPickerProps?: DayPickerProps
}

export default function DatePicker({ label, hint, date, onChange, error, onClear, ...props }: InputProps) {
  const name = label ? slugify(label) : undefined
  const min = props.min === undefined ? undefined : fromUnixTime(props.min)
  const max = props.max === undefined ? undefined : fromUnixTime(props.max)

  return (
    <>
      <label
        htmlFor={name}
        className={classNames(
          error ? 'text-red-600' : 'text-gray-600 focus-within:text-brand-green-dark',
          'block text-sm font-medium'
        )}
      >
        {label}
        <div className="mt-1 relative rounded-md shadow-sm">
          <DayPickerInput
            placeholder={dateFnsPormat(new Date(), FORMAT)}
            format={FORMAT}
            formatDate={formatDate}
            parseDate={parseDate}
            value={date ? fromUnixTime(date) : undefined}
            onDayChange={date => onChange(date ? getUnixTime(startOfDay(date)) : undefined)}
            inputProps={{
              name: name,
              type: "text",
              className: "input",
            }}
            dayPickerProps={{
              ...props.dayPickerProps,
              disabledDays: [
                min ? { before: min } : undefined,
                max ? { after: max } : undefined,
                props.disableWeekends ? { daysOfWeek: [0, 6] } : undefined,
              ],
              firstDayOfWeek: 1,
            }}
            classNames={{
              container: "mt-1",
              overlayWrapper: 'DayPickerInput-OverlayWrapper',
              overlay: classNames('DayPickerInput-Overlay mt-2 text-gray-800', props.pin === 'top' ? 'bottom-12' : ''),
            }}
          />
          {
            onClear &&
            <div className="absolute inset-y-0 right-0 pr-1 flex items-center">
              <button
                type="button"
                className="rounded-xl p-2 text-sm font-medium hover:bg-gray-50 text-gray-400"
                onClick={onClear}
              >
                <XIcon className="w-3.5 h-3.5" />
              </button>
            </div>
          }
        </div>
        {hint && <p className="mt-2 text-xs text-gray-400">{hint}</p>}
      </label>
      {
        error &&
        <p className="mt-2 text-sm text-red-600" id={name ? `${name}-error` : undefined}>
          {error}
        </p>
      }
    </>
  )
}
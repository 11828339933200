export type Errors = { [field: string]: string[] }

export class ErrorBag {
  message: string
  errors: Errors

  constructor({ message, errors }: { message: string, errors: Errors }) {
    this.message = message
    this.errors = errors
  }

  get(field: string) {
    if (this.errors && this.errors[field]) return this.errors[field][0]
  }

  all() {
    return Object.values(this.errors)
  }
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkAPI } from ".";
import { Centre, Stats } from "../types/centre";
import ApiService from "../api/api";
import { differenceInHours, format } from "date-fns";

type State = {
  loading: Boolean
  lastUpdated?: number
  month: number
  centres: Centre[]
  centre?: string | number
  stats: { [key: (string | number)]: Stats }
  fetchingStats: (string | number)[]
}

const initialState: State = {
  loading: false,
  month: (new Date()).valueOf(),
  centres: [],
  stats: {},
  fetchingStats: []
}

export const listCentres = createAsyncThunk<Centre[], string | undefined, ThunkAPI>(
  'centres/list',
  async (search, thunkAPI) => {
    return (await ApiService.listCentres(search)).data.data
  },
  {
    condition: (_, thunkAPI) => {
      // FIXME: swap this back, always fetching while working on forms

      // const { centres } = thunkAPI.getState()

      // return !centres.loading && (
      //   centres.lastUpdated &&
      //   differenceInHours(Date.now(), centres.lastUpdated) >= 1
      // )

      return true
    }
  }
)

export const getCentreStats = createAsyncThunk<Stats, string | number, ThunkAPI>(
  'centres/getStats',
  async (id, thunkAPI) => {
    const { centres } = thunkAPI.getState()
    const date = format((centres.month ?? new Date()), 'yyyy-MM-dd')
    return (await ApiService.getCentreStats(id, date)).data
  }
)

export const getCentre = createAsyncThunk<Centre, number, ThunkAPI>(
  'centres/get',
  async (id, thunkAPI) => {
    return (await ApiService.getCentre(id)).data.data
  },
  {
    condition: (id, thunkAPI) => {
      // const { centres } = thunkAPI.getState()
      // const centre = centres.centres.find(c => c.id === id)
      // return !centre || !centre.fetched

      return true
    }
  }
)

export const centreSlice = createSlice({
  name: 'centres',
  initialState,
  reducers: {
    putCentre: (state, action: PayloadAction<Centre>) => {
      const idx = state.centres.findIndex(c => c.id === action.payload.id)
      const centre = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.centres[idx] = centre
      } else {
        state.centres.push(centre)
      }
    },
    putMonth: (state, action: PayloadAction<number>) => {
      state.month = action.payload
    },
    setCentre: (state, action: PayloadAction<string | number | undefined>) => {
      state.centre = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(listCentres.pending, (state, _) => {
      state.loading = true
    })
    builder.addCase(listCentres.fulfilled, (state, action) => {
      state.centres = action.payload
      state.lastUpdated = Date.now()
      state.loading = false
    })
    builder.addCase(listCentres.rejected, (state, _) => {
      state.loading = false
    })
    builder.addCase(getCentre.fulfilled, (state, action) => {
      const idx = state.centres.findIndex(c => c.id === action.payload.id)
      const centre = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.centres[idx] = centre
      } else {
        state.centres.push(centre)
      }
    })
    builder.addCase(getCentreStats.fulfilled, (state, action) => {
      state.stats = { ...state.stats, [action.meta.arg]: action.payload }
      if (state.fetchingStats) {
        const idx = state.fetchingStats.findIndex(id => id === action.meta.arg)
        if (idx >= 0) {
          const fetchingStats = [...state.fetchingStats]
          fetchingStats.splice(idx, 1)
          state.fetchingStats = fetchingStats
        }
      }
    })
    builder.addCase(getCentreStats.pending, (state, action) => {
      state.fetchingStats = [...state.fetchingStats, action.meta.arg]
    })
    builder.addCase(getCentreStats.rejected, (state, action) => {
      if (state.fetchingStats) {
        const idx = state.fetchingStats.findIndex(id => id === action.meta.arg)
        if (idx >= 0) {
          const fetchingStats = [...state.fetchingStats]
          fetchingStats.splice(idx, 1)
          state.fetchingStats = fetchingStats
        }
      }
    })
  }
})

export const { putCentre, putMonth, setCentre } = centreSlice.actions

export default centreSlice.reducer

import { XCircleIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import { useState } from "react";
import { submitMeetingData } from "../../api/audit";
import useAuth from "../../hooks/useAuth";
import { useNotification } from "../../hooks/useNotification";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { getMeetings } from "../../store/auditSlice";
import { Meeting as MeetingType } from "../../types/audit";
import { Centre } from "../../types/centre";
import { dateToMonth, dateToString } from "../../utils";
import Switch from "../Switch";

type Props = {
  centre: Centre
  meeting?: MeetingType
  agendaItems: { name: string, completed: boolean }[]
  attendees: string[]
  setAgendaItems: React.Dispatch<React.SetStateAction<{ name: string, completed: boolean }[]>>
  setAttendees: React.Dispatch<React.SetStateAction<string[]>>
  readonly?: boolean
}

export default function Meeting({
  centre,
  meeting,
  agendaItems,
  setAgendaItems,
  attendees,
  setAttendees,
  readonly = false
}: Props) {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const month = useAppSelector(state => state.centres.month)
  const [updating, setUpdating] = useState(false)
  const notification = useNotification()

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setUpdating(true)
    try {
      const date = dateToMonth(month)
      await submitMeetingData(centre.id, {
        id: meeting?.id,
        items: agendaItems,
        attendees: attendees,
        meeting_at: date
      })
      dispatch(getMeetings({ centre: centre.id, month: date }))
    } catch (error: any) {
      notification.showError(
        'Unable to submit meeting for month',
        error?.response?.data?.message
      )
    } finally {
      setUpdating(false)
    }
  }

  return (
    <section>
      <form onSubmit={submit}>
        <div className="space-y-6">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label htmlFor="meeting-date" className="block text-sm font-medium text-gray-700">
                    Meeting Date
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="meeting-date"
                      id="meeting-date"
                      className="input"
                      value={format(month, 'MMMM yyyy')}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <div className="flex justify-between items-end">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Agenda Items
                    </label>
                    {!readonly && (
                      <button
                        type="button"
                        className="mt-2 ml-auto btn btn--secondary btn--small"
                        onClick={() => setAgendaItems(items => [...items, { name: '', completed: false }])}
                      >
                        Add Agenda Item
                      </button>
                    )}
                  </div>
                  <div className="mt-2 grid grid-cols-1 gap-2">
                    {agendaItems.map((item, index) => (
                      <div key={index} className="flex">
                        <div className="w-full mt-1 mr-4 relative rounded-md shadow-sm">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            disabled={readonly}
                            className="input"
                            value={item.name}
                            onChange={e => setAgendaItems(items => {
                              let newItems = [...items]
                              newItems[index] = { ...item, name: e.target.value }
                              return newItems
                            })}
                          />
                          {!readonly && (
                            <button
                              type="button"
                              className="absolute inset-y-0 right-0 pr-3 flex items-center"
                              onClick={() => setAgendaItems(items => {
                                if (items.length <= 1) {
                                  return [{ name: '', completed: false }]
                                }

                                let newItems = [...items]
                                newItems.splice(index, 1)
                                return newItems
                              })}
                            >
                              <XCircleIcon className="h-5 w-5 text-gray-300 hover:text-tertiary-400" aria-hidden="true" />
                            </button>
                          )}
                        </div>
                        <Switch
                          label="Completed"
                          enabled={item.completed}
                          setEnabled={checked => setAgendaItems(items => {
                            let newItems = [...items]
                            newItems[index] = { ...item, completed: checked }
                            return newItems
                          })}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3">
                  <div className="flex justify-between items-end">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Attendees
                    </label>
                    {!readonly && (
                      <button
                        type="button"
                        className="mt-2 ml-auto btn btn--secondary btn--small"
                        onClick={() => setAttendees(items => [...items, ''])}
                      >
                        Add Attendee
                      </button>
                    )}
                  </div>
                  <div className="mt-2 grid grid-cols-3 gap-2">
                    {attendees.map((item, index) => (
                      <div key={index} className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="input capitalize"
                          disabled={readonly}
                          value={item}
                          onChange={e => (
                            setAttendees(items => {
                              let newItems = [...items]
                              newItems[index] = e.target.value
                              return newItems
                            })
                          )}
                        />
                        {!readonly && (
                          <button
                            type="button"
                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                            onClick={() => (
                              setAttendees(items => {
                                if (items.length <= 1) {
                                  return ['']
                                }

                                let newItems = [...items]
                                newItems.splice(index, 1)
                                return newItems
                              })
                            )}
                          >
                            <XCircleIcon className="h-5 w-5 text-gray-300 hover:text-tertiary-400" aria-hidden="true" />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="inline-flex justify-center btn btn--primary"
              disabled={updating}
            >
              {updating ? 'Saving...' : 'Save'}
            </button>
          </div> */}
          </div>

          {user?.can('submit-centre-reports') && (
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center btn btn--primary"
                disabled={updating || readonly}
              >
                {updating ? 'Saving...' : 'Save'}
              </button>
            </div>
          )}
        </div>
      </form>
    </section>
  )
}
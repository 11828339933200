import { useEffect } from "react";
import { listBrands } from "../store/brandSlice";
import { Brand } from "../types/brand";
import { useAppDispatch, useAppSelector } from "./useRedux";

export default function useBrands(search?: string) {
  const dispatch = useAppDispatch()
  const brands: Brand[] = useAppSelector(state => state.brands.brands)

  useEffect(() => { dispatch(listBrands(search)) }, [dispatch, search])

  return brands
}
import { useEffect, useState } from "react"
import useRoles from "../hooks/useRoles"
import Select, { Option } from "./Select"

type Props = {
  selected?: string | number
  setSelected: (selected?: string | number) => void
}

const unselected = { label: 'Please Select' }

export default function RoleSelect({ selected, setSelected }: Props) {
  const roles = useRoles()
  const [options, setOptions] = useState<Option[]>([])
  const [selectedOption, setSelectedOption] = useState<Option>(unselected)

  useEffect(() => {
    setOptions([unselected].concat(roles.map(role => ({ id: role.name, label: role.name.replace('-', ' ') }))))
  }, [roles])

  return (
    <Select
      options={options}
      selected={selectedOption}
      setSelected={selected => {
        setSelectedOption(selected)
        setSelected(selected.id)
      }}
    />
  )
}
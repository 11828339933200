import { useContext, useEffect } from "react";
import { TitleContext } from "../context/TitleContext";

export default function useTitle(title: string, subtitle?: string, showMonth: boolean = false, showCentre: boolean = false) {
  const titleContext = useContext(TitleContext)

  useEffect(() => titleContext.setTitle(title), [title, titleContext])
  useEffect(() => titleContext.setSubtitle(subtitle), [subtitle, titleContext])
  useEffect(() => titleContext.setShowMonth(showMonth), [showMonth, titleContext])
  useEffect(() => titleContext.setShowCentre(showCentre), [showCentre, titleContext])
}

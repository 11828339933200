import { useContext, useEffect, useRef, useState } from "react";
import { NotificationContext, NotificationType } from "../components/Notification";

export function useNotification() {
  const notificationContext = useContext(NotificationContext)

  const show = (type: NotificationType, title?: string, description?: string) => {
    notificationContext.showNotification(type, title, description)
  }

  const showSuccess = (title?: string, description?: string) => {
    show('success', title, description)
  }

  const showError = (title?: string, description?: string) => {
    show('error', title, description)
  }

  const showWarning = (title?: string, description?: string) => {
    show('warning', title, description)
  }

  const showInfo = (title?: string, description?: string) => {
    show('info', title, description)
  }

  return { show, showSuccess, showError, showWarning, showInfo }
}

export function useNotificationProvider(): [
  {
    show: boolean,
    setShow: (show: boolean) => void,
    type: NotificationType,
    title?: string,
    description?: string,
  },
  (type: NotificationType, title?: string | undefined, description?: string | undefined) => void
] {
  const [show, setShow] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState<{
    type: NotificationType,
    title?: string,
    description?: string
  }>({
    type: 'success',
    title: 'Successfully Saved!',
    description: 'Brand Alfa Romeo has been successfully saved.',
  })
  const notificationTimeout = useRef<NodeJS.Timeout>()
  const triggerNotification = (type: NotificationType, title?: string, description?: string) => {
    setNotificationOptions({ type, title, description })
    setShow(true)
  }

  useEffect(() => {
    if (show) {
      if (notificationTimeout.current) {
        clearTimeout(notificationTimeout.current)
      }

      notificationTimeout.current = setTimeout(() => setShow(false), 3000)
    }
  }, [show])

  return [
    { ...notificationOptions, show, setShow },
    triggerNotification
  ]
}
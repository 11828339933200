import { XIcon } from "@heroicons/react/outline"
import { current } from "@reduxjs/toolkit"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { apiClient } from "../api/api"
import { useNotification } from "../hooks/useNotification"
import { useAppSelector } from "../hooks/useRedux"
import { ErrorBag } from "../types/errors"
import { classNames } from "../utils"

type Props = {
  id: string
  title?: string
  description?: string
  endpoint: string
  centre?: string | number
  date?: string
  params?: { key: string, value: any }[]
  uploadSuccessful?: () => void
  cancelled?: (failed: boolean) => void
  hasNameInput?: boolean
}

export default function FileImport({ endpoint, centre, date, params, ...props }: Props) {
  const [name, setName] = useState<string>()
  const [file, setFile] = useState<File>()
  const [dragging, setDragging] = useState(false)
  const [importing, setImporting] = useState(false)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [errors, setErrors] = useState<ErrorBag>()
  const notification = useNotification()
  const month = useAppSelector(state => state.centres.month)
  let currentDate = format(month, 'yyyy-MM-dd');
  if (date) {
    currentDate = date;
  }

  useEffect(() => {
    if (!name) {
      setName(file?.name)
    }
  }, [file?.name, name])

  const stopEvent = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onDragEnter = (e: React.DragEvent) => {
    stopEvent(e)
    setDragging(true)
  }

  const onDragLeave = (e: React.DragEvent) => {
    stopEvent(e)
    setDragging(false)
  }

  const onDrop = (e: React.DragEvent) => {
    setErrors(undefined)
    stopEvent(e)
    setDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0])
    }
  }

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      setFile(e.currentTarget.files[0])
    }
  }

  const reset = () => {
    setErrors(undefined)
    setImporting(false)
    setUploadPercent(0)
    setFile(undefined)
  }

  const onImport = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setErrors(undefined)
    if (!file) return
    setImporting(true)

    let form = new FormData()
    form.append('file', file, file.name)
    if (name) {
      form.append('name', name)
    }
    if (centre) {
      form.append('centre', centre.toString())
    }
    form.append('date', currentDate || format(new Date(), 'yyyy-MM-dd'))
    params?.forEach(param => {
      form.append(param.key, param.value)
    })

    try {
      let response = await apiClient.post(endpoint, form, {
        onUploadProgress: (e: ProgressEvent) => {
          setUploadPercent(Math.ceil(e.loaded / e.total * 100))
        }
      })
      if (response.status >= 200 && response.status < 300) {
        notification.showInfo(
          'File Successfully Uploaded',
        );
      }
      if (props.uploadSuccessful) {
        props.uploadSuccessful()
      }
      reset()
    } catch (error: any) {
      if (error.response && error.response.data) {
        setErrors(new ErrorBag(error.response.data))
      }

      if (props.cancelled) {
        props.cancelled(true)
      } else {
        const msg = error.response?.data?.message || 'An error occurred processing the uploaded file. Please check the file and upload again.'
        notification.showError("Failed to import file ", msg)
      }
      setImporting(false)
    }
  }

  const onCancel = () => {
    reset()
    if (props.cancelled) {
      props.cancelled(false)
    }
  }

  return (
    <form className="space-y-6 divide-y divide-gray-200" onSubmit={onImport}>
      <div className="shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-t-md">
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">{props.title || 'Import'}</h3>
                {props.description && (
                  <p className="mt-1 text-sm text-gray-500">
                    {props.description}
                  </p>
                )}
              </div>

              <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                {(props.hasNameInput && props.hasNameInput === true) && (
                  <div className="col-span-6 sm:col-span-4">
                    <label htmlFor="name" className="label">
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className="input"
                        required
                      />
                    </div>
                  </div>
                )}

                <div className="sm:col-span-6">
                  <div
                    className="flex justify-center p-4 border-2 border-gray-300 border-dashed rounded-md"
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDragOver={stopEvent}
                    onDrop={onDrop}
                  >
                    <div className="space-y-1 w-full text-center">
                      {
                        !dragging && file ? (
                          // relative flex items-center justify-center text-sm text-gray-600 border w-48 h-32 p-3 rounded-lg
                          <div className="relative w-full py-6">
                            <div className="hidden sm:block absolute top-0 right-0 -mt-2 -mr-2">
                              <button
                                type="button"
                                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                                onClick={() => reset()}
                              >
                                <span className="sr-only">Remove File</span>
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                            <p className="overflow-ellipsis overflow-hidden text-xs">{file.name}</p>
                          </div>
                        ) : (
                          <div className="w-full">
                            <svg
                              className={classNames(
                                dragging ? 'animate-bounce' : '',
                                "mx-auto h-12 w-12 text-gray-400"
                              )}
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex items-center justify-center text-sm text-gray-600">
                              <label
                                htmlFor={`${props.id}-file-upload`}
                                className="link"
                              >
                                <span>Upload a file</span>
                                <input id={`${props.id}-file-upload`} name={`${props.id}-file-upload`} type="file" className="sr-only" onChange={onFileChanged} />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            {/* <p className="text-xs text-gray-500">CSV, XLS, XLSX</p> */}
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>

                {importing && (
                  <div className="sm:col-span-6">
                    <div className="relative pt-1">
                      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-brand-green bg-opacity-60">
                        <div style={{ width: `${uploadPercent}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-secondary-400" />
                      </div>
                    </div>
                  </div>
                )}

                {errors && (
                  <div className="sm:col-span-6">
                    <div className="mb-1 font-medium text-red-600">Failures:</div>
                    <ul className="flex flex-col text-xs list-disc ml-4 text-red-600">
                      {errors?.all().map(error => (
                        <li className="mb-1">{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-md">
          <button
            type="button"
            className="mr-4 inline-flex justify-center btn btn--tertiary"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center btn btn--primary"
            disabled={importing}
          >
            {importing ? 'Importing...' : 'Import'}
          </button>
        </div>
      </div>
    </form>
  )
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkAPI } from ".";
import ApiService from "../api/api";
import { differenceInHours } from "date-fns";
import { User } from "../types/user";

type State = {
  loading: Boolean
  lastUpdated?: number
  users: User[]
}

const initialState: State = {
  loading: false,
  users: [],
}

export const listUsers = createAsyncThunk<User[], { search?: string, role?: string | number }, ThunkAPI>(
  'users/list',
  async ({ search, role }, thunkAPI) => {
    return (await ApiService.listUsers(search, role)).data.data
  },
  {
    condition: (_, thunkAPI) => {
      // FIXME: swap this back, always fetching while working on forms

      // const { users } = thunkAPI.getState()

      // return !users.loading && (
      //   users.lastUpdated &&
      //   differenceInHours(Date.now(), users.lastUpdated) >= 1
      // )

      return true
    }
  }
)

export const getUser = createAsyncThunk<User, number, ThunkAPI>(
  'users/get',
  async (id, thunkAPI) => {
    return (await ApiService.getUser(id)).data.data
  },
)

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    putUser: (state, action: PayloadAction<User>) => {
      const idx = state.users.findIndex(c => c.id === action.payload.id)
      const user = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.users[idx] = user
      } else {
        state.users.push(user)
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(listUsers.pending, (state, _) => {
      state.loading = true
    })
    builder.addCase(listUsers.fulfilled, (state, action) => {
      state.users = action.payload
      state.lastUpdated = Date.now()
      state.loading = false
    })
    builder.addCase(listUsers.rejected, (state, _) => {
      state.loading = false
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      const idx = state.users.findIndex(c => c.id === action.payload.id)
      const user = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.users[idx] = user
      } else {
        state.users.push(user)
      }
    })
  }
})

export const { putUser } = userSlice.actions

export default userSlice.reducer

import { PlusIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { Link } from "react-router-dom"
import useAuth from "../hooks/useAuth"
import useBrands from "../hooks/useBrands"
import { useDebounce } from "../hooks/useDebounce"
import useTitle from "../hooks/useTitle"

export default function Brands() {
  useTitle('Vehicle Brands')
  const user = useAuth()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const brands = useBrands(debouncedSearch)

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

          <div className="mb-2 flex justify-end">
            <div className="mr-4 w-64">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="input"
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <Link
              to="/brands/create"
              className="btn btn--primary"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add
            </Link>
          </div>

          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 font-header">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {brands.length > 0 ? brands.map((brand, brandIdx) => (
                  <tr key={brand.id} className={brandIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{brand.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {user?.can('update-brands') && (
                        <Link to={`/brands/${brand.id}/edit`} className="link">
                          Edit
                        </Link>
                      )}
                    </td>
                  </tr>
                )) : (
                  <tr className="bg-white">
                    <td colSpan={3} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">You have no Brands, create one to get started.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkAPI } from ".";
import ApiService from "../api/api";
import { differenceInHours } from "date-fns";
import { Role } from "../types/role";
import { Permission } from "../types/permission";

type State = {
  loading: Boolean
  lastUpdated?: number
  permissionsLastUpdated?: number
  roles: Role[]
  permissions: Permission[]
}

const initialState: State = {
  loading: false,
  roles: [],
  permissions: [],
}

export const listPermissions = createAsyncThunk<Permission[], boolean | undefined, ThunkAPI>(
  'roles/permissions',
  async (_, thunkAPI) => {
    return (await ApiService.listPermissions()).data.data
  },
  {
    condition: (force, thunkAPI) => {
      if (force) return true

      const { roles } = thunkAPI.getState()

      return roles.permissionsLastUpdated
        ? differenceInHours(Date.now(), roles.permissionsLastUpdated) >= 2
        : true
    }
  }
)

export const listRoles = createAsyncThunk<Role[], string | undefined, ThunkAPI>(
  'roles/list',
  async (search, thunkAPI) => {
    return (await ApiService.listRoles(search)).data.data
  },
  {
    condition: (_, thunkAPI) => {
      // FIXME: swap this back, always fetching while working on forms

      // const { roles } = thunkAPI.getState()

      // return !roles.loading && (
      //   roles.lastUpdated &&
      //   differenceInHours(Date.now(), roles.lastUpdated) >= 1
      // )

      return true
    }
  }
)

export const getRole = createAsyncThunk<Role, number, ThunkAPI>(
  'roles/get',
  async (id, thunkAPI) => {
    return (await ApiService.getRole(id)).data.data
  },
  {
    condition: (id, thunkAPI) => {
      const { roles } = thunkAPI.getState()
      const role = roles.roles.find(u => u.id === id)

      return !role || !role.fetched
    }
  }
)

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    putRole: (state, action: PayloadAction<Role>) => {
      const idx = state.roles.findIndex(c => c.id === action.payload.id)
      const role = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.roles[idx] = role
      } else {
        state.roles.push(role)
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(listRoles.pending, (state, _) => {
      state.loading = true
    })
    builder.addCase(listRoles.fulfilled, (state, action) => {
      state.roles = action.payload
      state.lastUpdated = Date.now()
      state.loading = false
    })
    builder.addCase(listRoles.rejected, (state, _) => {
      state.loading = false
    })
    builder.addCase(listPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload
      state.permissionsLastUpdated = Date.now()
    })
    builder.addCase(getRole.fulfilled, (state, action) => {
      const idx = state.roles.findIndex(c => c.id === action.payload.id)
      const role = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.roles[idx] = role
      } else {
        state.roles.push(role)
      }
    })
  }
})

export const { putRole } = roleSlice.actions

export default roleSlice.reducer

import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getBrand } from "../store/brandSlice";
import { useAppDispatch } from "./useRedux";

const selectBrandSelector = createSelector(
  (state: RootState) => state.brands.brands,
  (_: RootState, id: number) => id,
  (brands, id) => brands.find(brand => brand.id === id)
)

export default function useBrand(id: number) {
  const dispatch = useAppDispatch()
  const brand = useSelector((state: RootState) => selectBrandSelector(state, id))

  useEffect(() => {
    if (id) dispatch(getBrand(id))
  }, [id, dispatch])

  return brand
}
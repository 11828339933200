import { useEffect } from "react";
import { listCentres } from "../store/centreSlice";
import { Centre } from "../types/centre";
import { useAppDispatch, useAppSelector } from "./useRedux";

export default function useCentres(search?: string) {
  const dispatch = useAppDispatch()
  const centres: Centre[] = useAppSelector(state => state.centres.centres)

  useEffect(() => { dispatch(listCentres(search)) }, [dispatch, search])

  return centres
}
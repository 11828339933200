/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { classNames } from '../utils'

export type Option = {
  id?: string | number
  label: string
  desc?: string
  disabled?: boolean
}

export const defaultOption: Option = {
  label: 'Please Select',
  disabled: true
}

type Props = {
  label?: string
  options: Option[]
  selected: Option
  setSelected: (selected: Option) => void
}

export default function Select({ label, options, selected, setSelected }: Props) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="mb-1 block text-sm font-medium text-gray-700 capitalize">{label}</Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 hover:border-gray-400 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm">
              <span className="w-full inline-flex truncate">
                <span className="truncate capitalize">{selected.label}</span>
                <span className="ml-2 truncate text-gray-500">{selected.desc}</span>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.label}
                    disabled={option.disabled}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-secondary-500' : 'text-gray-700',
                        'cursor-default select-none relative py-2 pl-3 pr-9 capitalize'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate capitalize')}>
                            {option.label}
                          </span>
                          <span className={classNames(active ? 'text-gray-200' : 'text-gray-400', 'ml-2 truncate')}>
                            {option.desc}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-secondary-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

import { Link, useHistory } from 'react-router-dom'
import { format } from "date-fns"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { RouteComponentProps } from "react-router"
import CentreSelect from "../components/CentreSelect"
import CentreStats from "../components/CentreStats"
import Chart from "../components/Chart"
import FileRepository from "../components/FileRepository"
import QualityControlChecks from "../components/QualityControlChecks"
import Testers from "../components/Testers"
import useAuth from "../hooks/useAuth"
import useCentre from "../hooks/useCentre"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import useTitle from "../hooks/useTitle"
import { setCentre } from "../store/centreSlice"
import { DATE_STRING_FORMAT, formatTimestampString } from '../utils'
import Tqis from '../components/Tqis'

export default function Centre({ id }: { id: string | number }) {
  const dispatch = useAppDispatch()
  const month = useAppSelector(state => state.centres.month)
  const state = useAppSelector(state => state.centres.centre)
  const history = useHistory()
  const user = useAuth()
  const centre = useCentre(+id)
  const desc = centre
    ? `RAG Rating: ${centre.rag_rating?.toUpperCase()} | Last Visit: ${centre.last_visited_at ? formatTimestampString(centre.last_visited_at, DATE_STRING_FORMAT) : 'Never'}`
    : 'Dashboard'
  useTitle(centre?.name || 'Centre', desc, true, true)

  const setId = useRef(true)
  if (setId.current) {
    dispatch(setCentre(id))
    setId.current = false
  }

  useLayoutEffect(() => {
    if (centre?.isParent) {
      history.replace(`/centres/${centre?.id}/capture`);
    }
  },[history, centre])
  useLayoutEffect(() => {
    if (!state) {
      return
    }

    if (+id !== +state) {
      history.replace(
        history.location.pathname.replace(id.toString(), state.toString())
      )
    }
  }, [history, id, state])

  return (
    <>
      <div className="flex justify-end">
        <Link to={`/centres/${centre?.id}/capture`} className="mr-4 btn btn--small btn--tertiary">
          Reporting
        </Link>
        <Link to={`/centres/${centre?.id}/edit`} className="btn btn--small btn--tertiary">
          Edit
        </Link>
      </div>

      <div className="py-4 lg:py-6 grid md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
        <CentreStats centre={centre!} />
      </div>

      {user?.can('read-tqi') && (
        <>
          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-300" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-header font-medium leading-6 text-gray-700">TQIs</h3>
                  {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                {centre && <Tqis key={`${centre.id}-${month}`} centre={centre} allowAdd={false} showFull />}
              </div>
            </div>
          </div>
        </>
      )}

      {user?.can('read-users') && (
        <>
          <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
              <div className="border-t border-gray-300" />
            </div>
          </div>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-header font-medium leading-6 text-gray-700">Staff</h3>
                  {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                {centre && <Testers key={centre.id} centre={centre} />}
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-300" />
        </div>
      </div> */}

      {/* <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-header font-medium leading-6 text-gray-700">File Repository</h3>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <FileRepository centre={centre!} />
          </div>
        </div>
      </div> */}
    </>
  )
}
import { useAppSelector } from "./useRedux";
import { User as UserType } from "../types/auth"
import { useMemo } from "react";

export class User {
  user: UserType

  constructor(user: UserType) {
    this.user = user
  }

  get name() {
    return this.user.name
  }

  get email() {
    return this.user.name
  }

  get centres() {
    return this.user.centres
  }

  can(action: string) {
    return this.user.permissions.includes(action)
  }
}

export default function useAuth() {
  const user = useAppSelector(state => state.auth.authenticatedUser)
  const userClass = useMemo(() => {
    return user ? new User(user) : undefined
  }, [user])

  return userClass
}
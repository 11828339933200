import { useState } from "react"
import { useHistory } from "react-router-dom"
import useCentre from "../hooks/useCentre"
import useTitle from "../hooks/useTitle"
import api from "../api/api"
import DatePicker from "../components/DatePicket"
import { DATE_FORMAT, stringToUnix, unixToString } from "../utils"
import { getUnixTime } from "date-fns"
import { useNotification } from "../hooks/useNotification"

export default function EditTqi({ id }: { id: string }) {
  const centre = useCentre(+id)
  useTitle(centre?.name || 'Centre', 'Add TQI')
  const history = useHistory()
  const notification = useNotification()
  const [saving, setSaving] = useState(false)
  const [tester, setTester] = useState('')
  const [tests, setTests] = useState(0)
  const [vehicleAge, setVehicleAge] = useState(0)
  const [testTime, setTestTime] = useState(0)
  const [testsFailed, setTestsFailed] = useState(0)

  const [failBody, setFailBody] = useState(0)
  const [failBrakes, setFailBrakes] = useState(0)
  const [failBuses, setFailBuses] = useState(0)
  const [failVehId, setFailVehId] = useState(0)
  const [failLamps, setFailLamps] = useState(0)
  const [failNoise, setFailNoise] = useState(0)
  const [failRoadWheels, setFailRoadWheels] = useState(0)
  const [failSeatbeltInstallation, setFailSeatbeltInstallation] = useState(0)
  const [failSeatbeltSupplementary, setFailSeatbeltSupplementary] = useState(0)
  const [failSpeedometer, setFailSpeedometer] = useState(0)
  const [failSteering, setFailSteering] = useState(0)
  const [failSuspension, setFailSuspension] = useState(0)
  const [failTyres, setFailTyres] = useState(0)
  const [failVisibility, setFailVisibility] = useState(0)

  const formEls = [
    { get: failBody, set: setFailBody, title: 'Body (%)' },
    { get: failBrakes, set: setFailBrakes, title: 'Brakes (%)' },
    { get: failBuses, set: setFailBuses, title: 'Buses (%)' },
    { get: failVehId, set: setFailVehId, title: 'Veh_Id (%)' },
    { get: failLamps, set: setFailLamps, title: 'Lamps (%)' },
    { get: failNoise, set: setFailNoise, title: 'Noise (%)' },
    { get: failRoadWheels, set: setFailRoadWheels, title: 'Road Wheels (%)' },
    { get: failSeatbeltInstallation, set: setFailSeatbeltInstallation, title: 'Seatbelt Installation (%)' },
    { get: failSeatbeltSupplementary, set: setFailSeatbeltSupplementary, title: 'Seatbelt Supplementary (%)' },
    { get: failSpeedometer, set: setFailSpeedometer, title: 'Speedometer (%)' },
    { get: failSteering, set: setFailSteering, title: 'Steering (%)' },
    { get: failSuspension, set: setFailSuspension, title: 'Suspension (%)' },
    { get: failTyres, set: setFailTyres, title: 'Tyres (%)' },
    { get: failVisibility, set: setFailVisibility, title: 'Visibility (%)' },
  ]

  const [date, setDate] = useState(unixToString(getUnixTime(new Date()), DATE_FORMAT))

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)

    try {
      api.saveTqi({
        centre_id: centre?.id,
        tester_id: tester,
        tests_done: tests.toString(),
        avg_veh_age: vehicleAge.toString(),
        avg_test_time: testTime.toString(),
        avg_fail_percentage: testsFailed.toString(),
        tqi_for: date,
        fail_body: failBody.toString(),
        fail_brakes: failBrakes.toString(),
        fail_buses: failBuses.toString(),
        fail_veh_id: failVehId.toString(),
        fail_lamps: failLamps.toString(),
        fail_noise: failNoise.toString(),
        fail_road_wheels: failRoadWheels.toString(),
        fail_seatbelt_installation: failSeatbeltInstallation.toString(),
        fail_seatbelt_supplementary: failSeatbeltSupplementary.toString(),
        fail_speedometer: failSpeedometer.toString(),
        fail_steering: failSteering.toString(),
        fail_suspension: failSuspension.toString(),
        fail_tyres: failTyres.toString(),
        fail_visibility: failVisibility.toString(),
      })

      notification.showSuccess('Successfully Saved', `TQI for ${tester} saved.`);

      history.goBack()
    } catch (error: any) {
      notification.showError(
        'Unable to submit quality control checks',
        error?.response?.data?.message
      )
    } finally {
      setSaving(false)
    }
  }

  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div>
          {/* <h3 className="text-lg font-medium leading-6 text-gray-700">Add Tqi</h3> */}
          <h5 className="mt-1 text-sm text-gray-500">Create a new TQI record for a tester</h5>
        </div>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={submit}>
          <div className="shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-t-md">
              <div className="grid grid-cols-2 gap-6">
                <div className="col-span-2 md:col-span-1">
                  <label className="label">
                    Tester ID
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={tester}
                      onChange={e => setTester(e.target.value)}
                      className="input"
                      required
                    />
                  </div>
                </div>

                <div className="col-span-2 md:col-span-1">
                  <label className="label">
                    TQI For
                  </label>
                  <div className="mt-1">
                    <DatePicker
                      date={stringToUnix(date, DATE_FORMAT)}
                      onChange={date => date && setDate(unixToString(date, DATE_FORMAT))}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div className="col-span-2 sm:col-span-1">
                  <label className="label">
                    Tests Done
                  </label>
                  <div className="mt-1">
                    <input
                      id="number"
                      value={tests}
                      onChange={e => !isNaN(+e.target.value) && setTests(+e.target.value)}
                      className="input"
                      required
                    />
                  </div>
                </div>

                <div className="col-span-2 sm:col-span-1">
                  <label className="label">
                    Average vehicle age
                  </label>
                  <div className="mt-1">
                    <input
                      id="number"
                      value={vehicleAge}
                      onChange={e => !isNaN(+e.target.value) && setVehicleAge(+e.target.value)}
                      className="input"
                      required
                    />
                  </div>
                </div>

                <div className="col-span-2 sm:col-span-1">
                  <label className="label">
                    Average test time
                  </label>
                  <div className="mt-1">
                    <input
                      id="number"
                      value={testTime}
                      onChange={e => !isNaN(+e.target.value) && setTestTime(+e.target.value)}
                      className="input"
                      required
                    />
                  </div>
                </div>

                <div className="col-span-2 sm:col-span-1">
                  <label className="label">
                    Tests failed (%)
                  </label>
                  <div className="mt-1">
                    <input
                      id="number"
                      value={testsFailed}
                      onChange={e => !isNaN(+e.target.value) && setTestsFailed(+e.target.value)}
                      className="input"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 font-medium font-header text-lg">
                  Fail Areas
                </div>
                {formEls.map(el => (
                  <div key={el.title} className="col-span-3 sm:col-span-1">
                    <label className="label truncate">
                      {el.title}
                    </label>
                    <div className="mt-1">
                      <input
                        id="number"
                        value={el.get}
                        onChange={e => !isNaN(+e.target.value) && el.set(+e.target.value)}
                        className="input"
                        required
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-md">
              <button
                type="button"
                className="mr-4 inline-flex justify-center btn btn--tertiary"
                disabled={saving}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex justify-center btn btn--primary"
                disabled={saving}
              >
                {saving ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
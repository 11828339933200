import { addMonths, format, fromUnixTime, getUnixTime, isBefore, parse, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { deleteCalibrationData, submitCalibrationData } from "../../api/audit";
import { deleteFile, downloadFile, getFilesByType } from "../../api/files";
import useAuth from "../../hooks/useAuth";
import { useNotification } from "../../hooks/useNotification";
import { useAppDispatch } from "../../hooks/useRedux";
import { getCalibrationRecords } from "../../store/auditSlice";
import { CalibrationRecord } from "../../types/audit";
import { Centre } from "../../types/centre";
import { ErrorBag } from "../../types/errors";
import { File } from "../../types/file";
import { classNames, DATE_FORMAT, formatTimestampString, stringToUnix, TIMESTAMP_STRING_FORMAT, unixToString } from "../../utils";
import ConfirmationModal from "../ConfirmationModal";
import DatePicker from "../DatePicket";
import FileImport from "../FileImport";
import ImportModal from "../ImportModal";
import Select, { Option } from "../Select";

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

type Props = {
  centre: Centre
  calibrationRecords: Partial<CalibrationRecord>[]
  setCalibrationRecords: React.Dispatch<React.SetStateAction<Partial<CalibrationRecord>[]>>
}

const equipment: Option[] = [
  { label: "Exhaust Gas Analyser" },
  { label: "Diesel Smoke Meter" },
  { label: "Brake roller testers" },
  { label: "Headlamp beam tester" },
  { label: "Tapleymeter / Decelerometer" },
  { label: "Tyre tread Gauge calibrated using setting block" },
  { label: "MOT Ramp Shaker Plate Maintenance" },
]

const emptySelect = { label: "Please Select", disabled: true }

export default function CalibrationRecords({ centre, calibrationRecords, setCalibrationRecords }: Props) {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const [updating, setUpdating] = useState(false)
  const [upload, setUpload] = useState<{ endpoint: string, params?: { key: string, value: any }[] }>()
  const [showUpload, setShowUpload] = useState(false)
  const notification = useNotification()
  const [errors, setErrors] = useState<ErrorBag>()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmRecord, setConfirmRecord] = useState<Partial<CalibrationRecord>>()

  const [files, setFiles] = useState<File[]>([])
  useEffect(() => {
    if (MANAGEMENT_ENABLED) {
      return
    }

    getFilesByType(centre.id, 'calibration').then(response => setFiles(response.data.data))
  }, [centre])

  useEffect(() => {
    if (calibrationRecords.length <= 0) {
      addRecord()
    }
  }, [calibrationRecords])

  useEffect(() => {
    calibrationRecords.forEach((record, recordIdx) => {
      const date = getUnixTime(new Date())
      if (!record.last_calibrated_at) {
        updateRecordValue(recordIdx, 'last_calibrated_at', unixToString(date))
      }

      if (!record.next_calibration_at) {
        updateRecordValue(recordIdx, 'last_calibrated_at', unixToString(date))
      }
    })
  }, [])

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setUpdating(true)
    setErrors(undefined)

    try {
      await submitCalibrationData(calibrationRecords)
      dispatch(getCalibrationRecords({ centre: centre.id }))
      notification.showSuccess(
        'Calibration records saved.'
      )
    } catch (error: any) {
      setErrors(new ErrorBag(error.response.data))
      notification.showError(
        'Unable to submit calibration records',
        error?.response?.data?.message
      )
    } finally {
      setUpdating(false)
    }
  }

  const updateRecordValue = (recordIdx: number, key: any, value: any) => setCalibrationRecords(records => {
    const updated = [...records]
    updated[recordIdx] = { ...updated[recordIdx], [key]: value }
    return updated
  })

  const uploadEvidence = (record: Partial<CalibrationRecord>) => {
    setUpload({
      endpoint: `/management/calibration-records/${record.id}/evidence`
    })
    setShowUpload(true);
  }

  const addRecord = () => {
    setCalibrationRecords(records => [
      ...records,
      {
        centre_id: centre.id,
      }
    ])
  }

  const recordRag = (record: Partial<CalibrationRecord>) => {
    if (!record.next_calibration_at) {
      return
    }

    const calibrationDate = fromUnixTime(stringToUnix(record.next_calibration_at, DATE_FORMAT))
    const date = new Date()
    if (isBefore(calibrationDate, addMonths(date, 1))) {
      return 'bg-red-300'
    } else if (isBefore(calibrationDate, addMonths(date, 2))) {
      return 'bg-yellow-300'
    }

    return 'bg-green-300'
  }

  if (!MANAGEMENT_ENABLED) {
    return (
      <section className="space-y-6">
        <FileImport id="calibration" title="Calibration Import" endpoint="/import/calibration" centre={centre!.id} />
        <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 font-header">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  Created At
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {files.length > 0 ? files.map((file, fileIdx) => (
                <tr key={file.id} className={fileIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {file.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatTimestampString(file.created_at)}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      type="button"
                      className="link"
                      onClick={() => downloadFile(file)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              )) : (
                <tr className="bg-white">
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-300" colSpan={4}>
                    No files.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    )
  }

  return (
    <section>
      <form onSubmit={submit}>
        <div className="space-y-6">
          {calibrationRecords.map((record, recordIdx) => {
            const rag = recordRag(record)

            return (
              <div key={recordIdx} className="relative shadow sm:rounded-md">
                <div className={classNames('absolute sm:rounded-t-md h-2 w-full', rag || '')}></div>
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-t-md">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Equipment
                      </label>
                      <div className="mt-1">
                        {/* <input
                          type="text"
                          className="input"
                          value={record.equipment}
                          onChange={e => updateRecordValue(recordIdx, 'equipment', e.target.value)}
                        /> */}
                        <Select
                          options={equipment}
                          selected={equipment.find(e => e.label === record.equipment) || emptySelect}
                          setSelected={option => {
                            updateRecordValue(recordIdx, 'equipment', option.label)
                          }}
                        />
                        {errors?.get(`records.${recordIdx}.equipment`) && (
                          <small className="text-red-600">Equipment type is required.</small>
                        )}
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Serial
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          className="input"
                          value={record.serial}
                          onChange={e => updateRecordValue(recordIdx, 'serial', e.target.value)}
                        />
                        {errors?.get(`records.${recordIdx}.serial`) && (
                          <small className="text-red-600">Serial is required.</small>
                        )}
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Supplier
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          className="input"
                          value={record.supplier_name || ''}
                          onChange={e => updateRecordValue(recordIdx, 'supplier_name', e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Last Calibrated
                      </label>
                      <div className="mt-1">
                        <DatePicker
                          date={record.last_calibrated_at ? stringToUnix(record.last_calibrated_at, DATE_FORMAT) : undefined}
                          onChange={date => updateRecordValue(recordIdx, 'last_calibrated_at', date ? unixToString(date, DATE_FORMAT) : undefined)}
                        />
                      </div>
                    </div>

                    <div className="col-span-3 sm:col-span-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Next Calibration
                      </label>
                      <div className="mt-1">
                        <DatePicker
                          date={record.next_calibration_at ? stringToUnix(record.next_calibration_at, DATE_FORMAT) : undefined}
                          onChange={date => updateRecordValue(recordIdx, 'next_calibration_at', date ? unixToString(date, DATE_FORMAT) : undefined)}
                        />
                      </div>
                    </div>
                    {record.supporting_files && record.supporting_files.length > 0 && (
                      <div className="col-span-3">
                        <span className="block mb-1 text-sm font-medium text-gray-700">
                          Evidence
                        </span>
                        <div className="border rounded-md overflow-hidden shadow-sm">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50 font-header">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                >
                                  Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                >
                                  Created At
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                  <span className="sr-only">Actions</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {record.supporting_files.map((file, fileIdx) => (
                                <tr key={file.id} className={fileIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {file.name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatTimestampString(file.created_at)}</td>
                                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{file.created_at}</td> */}
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <button
                                      type="button"
                                      className="link"
                                      onClick={() => downloadFile(file)}
                                    >
                                      View
                                    </button>
                                    <button
                                      type="button"
                                      className="ml-2 link link--destructive"
                                      onClick={async () => {
                                        await deleteFile(file.centre_id, file.id)
                                        dispatch(getCalibrationRecords({ centre: centre.id }))
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 flex justify-end sm:px-6 sm:rounded-b-md">
                  <button
                    type="button"
                    className={classNames(
                      "btn btn--tertiary btn--small",
                      !record.id ? 'invisible' : '',
                    )}
                    disabled={!record.id}
                    onClick={() => uploadEvidence(record)}
                  >
                    Add Evidence
                  </button>
                  {(record.id && user?.can('delete-calibration-records')) && (
                    <button
                      type="button"
                      className="ml-4 btn btn--small btn--destructive"
                      disabled={updating}
                      onClick={() => {
                        setConfirmRecord(record)
                        setConfirmOpen(true)
                      }}
                    >
                      Delete
                    </button>
                  )}
                  {!record.id && (
                    <button
                      type="button"
                      className="ml-4 btn btn--small btn--destructive"
                      disabled={updating}
                      onClick={() => setCalibrationRecords(records => {
                        const updated = [...records]
                        updated.splice(recordIdx, 1)
                        return updated
                      })}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            )
          })}

          {user?.can('submit-centre-reports') && (
            <div className="flex justify-end">
              <button
                type="button"
                className="inline-flex justify-center btn btn--tertiary mr-4"
                disabled={updating}
                onClick={addRecord}
              >
                Add Record
              </button>
              <button
                type="submit"
                className="inline-flex justify-center btn btn--primary"
                disabled={updating}
              >
                {updating ? 'Saving...' : 'Save'}
              </button>
            </div>
          )}
        </div>
      </form>

      <ConfirmationModal
        action="Delete"
        title={confirmRecord?.equipment}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={async () => {
          await deleteCalibrationData(confirmRecord!.id!)
          dispatch(getCalibrationRecords({ centre: centre.id }))
        }}
      />

      {upload && (
        <ImportModal
          endpoint={upload.endpoint}
          centre={centre.id}
          open={showUpload}
          setOpen={setShowUpload}
          uploadSuccessful={() => dispatch(getCalibrationRecords({ centre: centre.id }))}
        />
      )}
    </section>
  )
}
import { createContext } from "react";

export type TitleContextProps = {
  title?: string
  subtitle?: string
  showMonth: boolean
  showCentre: boolean
  setTitle: (title?: string) => void
  setSubtitle: (subtitle?: string) => void
  setShowMonth: (show: boolean) => void
  setShowCentre: (show: boolean) => void
}

export const TitleContext = createContext<TitleContextProps>({
  showMonth: false,
  showCentre: false,
  setTitle: () => {},
  setSubtitle: () => {},
  setShowMonth: () => {},
  setShowCentre: () => {},
});
import { File } from "../types/file";
import { apiClient } from "./api";

export function getFiles(centre: string | number, search?: string) {
  return apiClient.get(`/centres/${centre}/files`, {
    params: { search }
  })
}

export async function downloadFile(file: File) {
  const response = await apiClient.post(`/download/${file.id}`, undefined, { responseType: 'blob' })
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', file.name);
  document.body.appendChild(link);
  link.click();
}

export function getFilesByType(centre: string | number, type: string, search?: string) {
  return apiClient.get(`/centres/${centre}/files`, {
    params: { type, search }
  })
}

export function deleteFile(centre: string | number, file: string | number) {
  return apiClient.delete(`centres/${centre}/files/${file}`);
}
import { format, parse, startOfMonth, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { putMonth } from "../store/centreSlice";
import Select, { Option } from "./Select"

const FORMAT = 'MMMM yyyy'
const defaultDate = new Date()

export default function MonthSelector({ updateState = false, onChange }: { updateState?: boolean, onChange?: (selected?: number) => void }) {
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.centres.month)
  const [months, setMonths] = useState<Option[]>([])
  const [month, setMonth] = useState<Option>({ id: state, label: format((state || defaultDate), FORMAT) })

  useEffect(() => {
    let date = startOfMonth(new Date())
    const dates: Option[] = [];

    while (dates.length <= 24) {
      dates.push({
        id: date.valueOf(),
        label: format(date, FORMAT)
      });
      date = subMonths(date, 1)
    }

    setMonths(dates)
  }, [])

  useEffect(() => {
    setMonth(months.find(o => o.id === state) || { id: state, label: format((state || defaultDate), FORMAT) });
  }, [months, state])

  return (
    <Select
      options={months}
      selected={month}
      setSelected={selected => {
        setMonth(selected)

        if (selected.id && updateState) {
          dispatch(putMonth(+selected.id))
        }

        if (onChange) {
          onChange(selected.id ? +selected.id : undefined)
        }
      }}
    />
  )
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkAPI } from ".";
import { getCalibrationData, getMeetingData, getQualityControlData, getTestLogData, getTqiCheckData, getTrainingRecordsData } from "../api/audit";
import { CalibrationRecord, Meeting, QualityControlCheck, TestLog, TqiCheck, TrainingRecord } from "../types/audit";
import { Pagination } from "../types/pagination";

type State = {
  [centre: string | number]: undefined | {
    meetings?: Meeting[]
    qualityControlChecks?: QualityControlCheck[]
    calibrationRecords?: CalibrationRecord[]
    testLogs?: {
      data: TestLog[]
      pagination: Pagination
    }
    tqiChecks?: TqiCheck[]
    training?: TrainingRecord[]
  }
} & { tab?: string }

type Payload = {
  centre: string | number,
  month?: string
}

const initialState: State = {}

export const getMeetings = createAsyncThunk<Meeting[], Payload, ThunkAPI>(
  'audit/getMeetings',
  async ({ centre, month }) => {
    return (await getMeetingData(centre, month)).data.data
  },
)

export const getQualityControlChecks = createAsyncThunk<QualityControlCheck[], Payload, ThunkAPI>(
  'audit/getQualityControlChecks',
  async ({ centre, month }) => {
    return (await getQualityControlData(centre, month)).data.data
  }
)

export const getCalibrationRecords = createAsyncThunk<CalibrationRecord[], Payload, ThunkAPI>(
  'audit/getCalibrationRecords',
  async ({ centre, month }) => {
    return (await getCalibrationData(centre, month)).data.data
  }
)

export const getTestLogs = createAsyncThunk<{ data: TestLog[], meta: Pagination }, Payload & { page?: number, sort?: string, per_page?: number }, ThunkAPI>(
  'audit/getTestLogs',
  async ({ centre, month, page, sort , per_page }) => {
    return (await getTestLogData(centre, month, page, sort, per_page)).data
  },
)

export const getTqiChecks = createAsyncThunk<TqiCheck[], Payload, ThunkAPI>(
  'audit/getTqiChecks',
  async ({ centre, month }) => {
    return (await getTqiCheckData(centre, month!)).data.data
  },
)

export const getTrainingRecords = createAsyncThunk<TrainingRecord[], { centre: string | number, year: string }, ThunkAPI>(
  'audit/getTrainingRecords',
  async ({ centre, year }) => {
    return (await getTrainingRecordsData(centre, year)).data.data
  },
)

export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<string>) => {
      state.tab = action.payload
    },
    setMeetingData: (state, action: PayloadAction<{ centre: string | number, meeting: Meeting }>) => {
      const { centre, meeting } = action.payload
      const meetings = state[centre]?.meetings
      if (meetings) {
        const idx = meetings.findIndex(m => m.id === meeting.id)
        if (idx !== -1) {
          meetings[idx] = meeting
        } else {
          meetings.push(meeting)
        }
      } else {
        state[centre] = { ...state[centre], meetings: [meeting] }
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getMeetings.fulfilled, (state, action) => {
      const centreState = state[action.meta.arg.centre]
      if (centreState) {
        state[action.meta.arg.centre] = { ...centreState, meetings: action.payload }
      } else {
        state[action.meta.arg.centre] = { meetings: action.payload }
      }
    })
    builder.addCase(getQualityControlChecks.fulfilled, (state, action) => {
      state[action.meta.arg.centre] = {
        ...state[action.meta.arg.centre],
        qualityControlChecks: action.payload,
      }
    })
    builder.addCase(getCalibrationRecords.fulfilled, (state, action) => {
      state[action.meta.arg.centre] = {
        ...state[action.meta.arg.centre],
        calibrationRecords: action.payload,
      }
    })
    builder.addCase(getTestLogs.fulfilled, (state, action) => {
      state[action.meta.arg.centre] = {
        ...state[action.meta.arg.centre],
        testLogs: {
          data: action.payload.data,
          pagination: action.payload.meta
        }
      }
    })
    builder.addCase(getTqiChecks.fulfilled, (state, action) => {
      state[action.meta.arg.centre] = {
        ...state[action.meta.arg.centre],
        tqiChecks: action.payload
      }
    })
    builder.addCase(getTrainingRecords.fulfilled, (state, action) => {
      state[action.meta.arg.centre] = {
        ...state[action.meta.arg.centre],
        training: action.payload
      }
    })
  },
})

export const { setTab, setMeetingData } = auditSlice.actions

export default auditSlice.reducer

import { PlusIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { Link } from "react-router-dom"
import useUsers from "../hooks/useUsers"
import useTitle from "../hooks/useTitle"
import { classNames } from "../utils"
import { useDebounce } from "../hooks/useDebounce"
import RoleSelect from "../components/RoleSelect"

export default function Users() {
  useTitle('Users')
  const [search, setSearch] = useState('')
  const [role, setRole] = useState<string | number>()
  const debouncedSearch = useDebounce(search, 300)
  const users = useUsers(debouncedSearch, role)

  return (
    <div className="flex flex-col">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="mb-2 flex justify-end">
            <div className="mr-4 w-64">
              <RoleSelect selected={role} setSelected={setRole} />
            </div>
            <div className="mr-4 w-64">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="input"
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <Link
              to="/users/create"
              className="btn btn--primary"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add
            </Link>
          </div>

          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 font-header">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tester ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Centre(s)
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Roles
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? users.map((user, userIdx) => (
                  <tr key={user.id} className={userIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.tester_id}</td>
                    <td className="px-6 py-4 max-w-sm text-sm text-gray-500">
                      <div className="flex flex-col">
                        {user.roles?.includes('admin') ? (
                          <span className="font-bold">All Centres</span>
                        ) : (
                          user.centreNames?.map((name, nameIdx) => (
                            <span
                              key={name}
                              className={classNames(
                                nameIdx < (user.centreNames!.length - 1) ? 'pr-1' : ''
                              )}
                            >
                              {name}{nameIdx < (user.centreNames!.length - 1) ? ',' : ''}
                            </span>
                          ))
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.roles?.map((role, roleIdx) => (
                        <span
                          key={role}
                          className={classNames(
                            'inline-flex items-center px-2 py-1 rounded text-xs font-medium bg-secondary-400 text-white capitalize',
                            roleIdx > 0 ? 'ml-2' : ''
                          )}
                        >
                          {role.replace('-', ' ')}
                        </span>
                      ))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={`/users/${user.id}/edit`} className="link">
                        Edit
                      </Link>
                    </td>
                  </tr>
                )) : (
                  <tr className="bg-white">
                    <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">You have no Users assigned.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

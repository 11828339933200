import { PlusIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { Link } from "react-router-dom"
import useRoles from "../hooks/useRoles"
import { useDebounce } from "../hooks/useDebounce"
import useTitle from "../hooks/useTitle"
import useAuth from "../hooks/useAuth"

export default function Roles() {
  useTitle('Roles')
  const user = useAuth()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const roles = useRoles(debouncedSearch)

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

          <div className="mb-2 flex justify-end">
            <div className="w-64">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="input"
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            {user?.can('create-roles') && (
              <Link
                to="/roles/create"
                className="ml-4 btn btn--primary"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                Add
              </Link>
            )}
          </div>

          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 font-header">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {roles.length > 0 ? roles.map((role, roleIdx) => (
                  <tr key={role.id} className={roleIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 capitalize">{role.name.replace('-', ' ')}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {user?.can('update-roles') && (
                        <Link to={`/roles/${role.id}/edit`} className="link">
                          Edit
                        </Link>
                      )}
                    </td>
                  </tr>
                )) : (
                  <tr className="bg-white">
                    <td colSpan={3} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">You have no Roles, create one to get started.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

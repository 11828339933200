import { useEffect } from "react";
import { listUsers } from "../store/userSlice";
import { User } from "../types/user";
import { useAppDispatch, useAppSelector } from "./useRedux";

export default function useUsers(search?: string, role?: string | number) {
  const dispatch = useAppDispatch()
  const users: User[] = useAppSelector(state => state.users.users)

  useEffect(() => { dispatch(listUsers({ search, role })) }, [dispatch, search, role])

  return users
}
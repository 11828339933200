import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getCentre } from "../store/centreSlice";
import { useAppDispatch } from "./useRedux";

const selectCentreSelector = createSelector(
  (state: RootState) => state.centres.centres,
  (_: RootState, id: number) => id,
  (centres, id) => centres.find(centre => centre.id === id)
)

export default function useCentre(id: number) {
  const dispatch = useAppDispatch()
  const centre = useSelector((state: RootState) => selectCentreSelector(state, id))

  useEffect(() => {
    if (id) dispatch(getCentre(id))
  }, [id, dispatch])

  return centre
}
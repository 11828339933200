import { useEffect, useState } from "react";
import api from "../../api/api";
import { useNotification } from "../../hooks/useNotification";
import TipTap from "../TipTap";

export default function Report() {
  const notification = useNotification()
  const [report, setReport] = useState('')
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    setUpdating(true)
    api.getReport()
      .then(response => setReport(response.data.value))
      .finally(() => setUpdating(false))
  }, [])

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setUpdating(true)
    try {
      await api.saveReport(report)
    } catch (error: any) {
      notification.showError('Failed to save setting', error?.response?.data?.message || '')
    } finally {
      setUpdating(false)
    }
  }

  return (
    <section>
      <form onSubmit={submit}>
        <div className="space-y-6">
          <TipTap key={String(updating)} content={report} onChange={setReport} />

          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-4 inline-flex justify-center btn btn--primary"
              disabled={updating}
            >
              {updating ? 'Sending...' : 'Save'}
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}
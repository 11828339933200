import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PieChart, LineChart, Pie, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts'
import { Centre } from '../types/centre'
import { getCentreData } from '../api/chart'
import Switch from './Switch'
import { dateToMonth, randomColor } from '../utils'
import { useAppSelector } from '../hooks/useRedux'

type Props = {
  centre: Centre
  type: string
  name?: string
  year?: string | number
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type DataPoint = {
  name: string
  [data: string]: string | number
}

export default function Chart({ centre, type, name, year, open, setOpen }: Props) {
  const month = useAppSelector(state => state.centres.month)
  const [testers, setTesters] = useState<string[]>([])
  const [data, setData] = useState<DataPoint[]>([])
  const [split, setSplit] = useState(false);

  useEffect(() => {
    const date = dateToMonth(month)
    getCentreData(centre.id, type, year, split, date).then(response => {
      setData(response.data.data)
      setTesters(response.data.testers || [])
    })
  }, [centre, type, year, split, month])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:max-h-96 sm:h-screen sm:p-6">
              <div className="flex justify-between items-center">
                <h3 className="mb-4 text-center text-lg leading-6 font-medium text-gray-900">{name}</h3>
                {type !== 'brands' && <Switch label='Split by Tester' enabled={split} setEnabled={enabled => setSplit(enabled)} />}
              </div>
              <ResponsiveContainer width="100%" height="80%">
                {type === 'brands' ? (
                  <PieChart>
                    <Tooltip />
                    <Pie
                      data={data}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={randomColor()} />
                      ))}
                    </Pie>
                  </PieChart>
                ) : (
                  <LineChart data={data}>
                    <CartesianGrid strokeDasharray="6" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {split && testers.length > 0 ? testers.map(tester => (
                      <Line type="monotone" dataKey={tester} stroke={randomColor()} />
                    )) : (
                      <Line type="monotone" dataKey="data" stroke="#278AA8" name={name} />
                    )}
                  </LineChart>
                )}
              </ResponsiveContainer>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

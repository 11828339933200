import { CalibrationRecord, QualityControlCheck, TqiCheck, TrainingRecord } from "../types/audit";
import { apiClient } from "./api";

// Meetings
export function getMeetingData(centre: string | number, month?: string) {
  return apiClient.get(`/centres/${centre}/meetings`, { params: { month } })
}

export function submitMeetingData(centre: string | number, payload: { id?: string | number, items: { name: string, completed: boolean }[], attendees: string[], meeting_at: string }) {
  return apiClient.post(`/centres/${centre}/meetings`, payload)
}

// Quality Control
export function getQualityControlData(centre: string | number, month?: string) {
  return apiClient.get('/quality-control-checks', { params: { centre, month } })
}

export function submitQualityControlData(
  centre_id: string | number,
  checks: Partial<QualityControlCheck>[],
  deleteChecks?: (string | number)[]
) {
  return apiClient.post('/management/quality-control-checks', { centre_id, checks, deleteChecks })
}

// Calibration Data
export function getCalibrationData(centre: string | number, month?: string) {
  return apiClient.get('/calibration-records', { params: { centre, month } })
}
export function deleteCalibrationData(id: string | number) {
  return apiClient.delete(`/calibration-records/${id}`)
}

export function submitCalibrationData(payload: Partial<CalibrationRecord>[]) {
  return apiClient.post('/management/calibration-records', { records: payload })
}

// TQI Checks
export function getTqiCheckData(centre: string | number, month: string) {
  return apiClient.get('/management/tqi-checks', { params: { centre_id: centre, month } })
}

export function submitTqiCheckData(centre: string | number, month: string, checks: Partial<TqiCheck>[]) {
  return apiClient.post('/management/tqi-checks', { centre_id: centre, month, checks })
}

// Test Logs
export function getTestLogData(centre: string | number, month?: string, page?: number, sort?: string, per_page?: number) {
  return apiClient.get(`/centres/${centre}/tests`, { params: { month, page, sort, per_page } })
}

export type TestLogDataPayload = { id: string, cost: string | null, account_no: string | null }
export function submitTestLogData(logs: TestLogDataPayload[]) {
  return apiClient.post('/management/tests', { logs })
}

// Training
export function getTrainingRecordsData(centre_id: string | number, year: string) {
  return apiClient.get(`/management/training`, { params: { centre_id, year } })
}

export function submitTrainingRecordsData(centre_id: string | number, year: string, records: Partial<TrainingRecord>[]) {
  return apiClient.post(`/management/training`, { centre_id, year, records })
}

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import auditSlice from './auditSlice'
import authSlice from './authSlice'
import brandSlice from './brandSlice'
import centreSlice from './centreSlice'
import fileSlice from './fileSlice'
import roleSlice from './roleSlice'
import tqiSlice from './tqiSlice'
import userSlice from './userSlice'
import regionalSlice from './regionalSlice'

export const rootReducer = combineReducers({
  auth: authSlice,
  centres: centreSlice,
  brands: brandSlice,
  users: userSlice,
  roles: roleSlice,
  files: fileSlice,
  audit: auditSlice,
  tqi: tqiSlice,
  regional: regionalSlice,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ThunkAPI = {dispatch: AppDispatch, state: RootState}

import { useState } from "react";
import Report from "../components/Settings/Report";
import useTitle from "../hooks/useTitle";
import { classNames } from "../utils";

type Section = {
  id: string | number
  name?: string
  type?: string
}

const sections: Section[] = [
  { id: 'report', name: 'Monthly Report' },
];

export default function Settings() {
  useTitle('Settings')
  const [current, setCurrent] = useState<Section>(sections[0])

  const renderSection = () => {
    switch (current.type ?? current.id) {
      case 'report': return <Report />
    }
  }

  return (
    <div className="py-4 lg:py-6">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <nav className="space-y-2">
            {sections.map(section => {
              const isCurrent: boolean = section.id === current.id
              return (
                <button
                  key={section.id}
                  onClick={() => setCurrent(section)}
                  className={classNames(
                    isCurrent
                      ? 'bg-gray-100 text-secondary-500 hover:bg-gray-50'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100',
                    'w-full group rounded-md px-3 py-2 flex items-center text-sm font-medium transition-all'
                  )}
                  aria-current={isCurrent ? 'page' : undefined}
                >
                  <span className="truncate capitalize">{section.name ?? section.id}</span>
                </button>
              )
            })}
          </nav>
        </aside>

        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          {renderSection()}
        </div>
      </div>
    </div>
  )
}
import { useContext, useState } from 'react'
import { Route, RouteComponentProps, Switch, useParams } from 'react-router'
import { TitleContext } from '../context/TitleContext'
import useAuth from '../hooks/useAuth'
import Brands from './Brands'
import Centre from './Centre'
import Centres from './Centres'
import EditBrand from './EditBrand'
import EditCentre from './EditCentre'
import EditRole from './EditRole'
import EditUser from './EditUser'
import NavHeader from '../components/NavHeader'
import Profile from './Profile'
import Roles from './Roles'
import Users from './Users'
import CentreSelect from '../components/CentreSelect'
import Select from '../components/Select'
import MonthSelector from '../components/MonthSelect'
import ManagementDataCapture from './ManagementDataCapture'
import Dashboard from './Dashboard'
import Settings from './Settings'
import EditTqi from './EditTqi'
import TestLogsPage from './TestLogsPage'

export default function Shell() {
  const user = useAuth()
  const titleContext = useContext(TitleContext)
  const [selectedCentre, setSelectedCentre] = useState<string | number>()

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <NavHeader />
        <div className="py-10 flex-1 flex flex-col">
          {
            titleContext.title &&
            <header>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between">
                <div>
                  <h1 className="font-header text-3xl font-bold leading-tight text-gray-900">{titleContext.title}</h1>
                  {titleContext.subtitle && <h3 className="font-header pt-2 text-lg font-medium leading-6 text-gray-700">{titleContext.subtitle}</h3>}
                </div>
                <div className='flex'>
                  {titleContext.showCentre && (
                    <div className="w-64 mr-4">
                      <CentreSelect updateState />
                    </div>
                  )}
                  {titleContext.showMonth && (
                    <div className="w-64">
                      <MonthSelector updateState />
                    </div>
                  )}
                </div>
              </div>
            </header>
          }
          <main className="flex-1 flex">
            <div className="flex-1 max-w-7xl mx-auto py-2 sm:px-6 lg:px-8">
              <Switch>
                <Route path="/centres/create" component={EditCentre} />
                <Route path="/centres/:id/edit" component={EditCentre} />
                <Route path="/centres/:id/capture" component={ManagementDataCapture} />
                <Route
                  path="/centres/:id/test-logs"
                  render={({ match }: RouteComponentProps<{ id: string }>) => <TestLogsPage id={match.params.id} />}
                />
                <Route
                  path="/centres/:id/tqi"
                  render={({ match }: RouteComponentProps<{ id: string }>) => <EditTqi id={match.params.id} />}
                />
                <Route
                  path="/centres/:id"
                  render={({ match }: RouteComponentProps<{ id: string }>) => <Centre id={match.params.id} />}
                />
                <Route path="/centres" component={Centres} />
                <Route path="/brands/create" component={EditBrand} />
                <Route path="/brands/:id/edit" component={EditBrand} />
                <Route path="/brands" component={Brands} />
                <Route path="/users/create" component={EditUser} />
                <Route path="/users/:id/edit" component={EditUser} />
                <Route path="/users" component={Users} />
                <Route path="/roles/create" component={EditRole} />
                <Route path="/roles/:id/edit" component={EditRole} />
                {user?.can('read-roles') && <Route path="/roles" component={Roles} />}
                <Route path="/profile" component={Profile} />
                <Route path="/settings" component={Settings} />
                <Route
                  path="/"
                  render={() => (
                    (user?.centres && user.centres.length === 1)
                      ? <Centre id={user.centres[0]} />
                      : <Dashboard />
                  )}
                />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

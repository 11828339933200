import { format, startOfMonth } from "date-fns"
import { useEffect, useState } from "react"
import { submitTqiCheckData } from "../../api/audit"
import useAuth from "../../hooks/useAuth"
import { useNotification } from "../../hooks/useNotification"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { listTqis } from "../../store/tqiSlice"
import { TqiCheck } from "../../types/audit"
import { Centre } from "../../types/centre"
import { dateToMonth } from "../../utils"
import FileImport from "../FileImport"
import Tqis from "../Tqis"

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

type Props = {
  centre: Centre
  tqiChecks: Partial<TqiCheck>[]
  setTqiChecks: React.Dispatch<React.SetStateAction<Partial<TqiCheck>[]>>
}

export default function Tqi({ centre, tqiChecks, setTqiChecks }: Props) {
  const dispatch = useAppDispatch()
  const user = useAuth()
  const month = useAppSelector(state => state.centres.month)
  const [updating, setUpdating] = useState(false)
  const notification = useNotification()

  const getCheck = (check: string) => {
    return tqiChecks.find(t => t.check === check)?.comments || ''
  }

  const setCheck = (check: string, comments: string) => setTqiChecks(checks => {
    const idx = checks.findIndex(t => t.check === check)
    if (idx < 0) {
      return [...checks, { check, comments }]
    }

    const newChecks = [...checks]
    newChecks[idx] = { ...newChecks[idx], comments }

    return newChecks
  })

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    setUpdating(true)
    try {
      await submitTqiCheckData(centre.id, dateToMonth(month), tqiChecks)
    } catch (error: any) {
      notification.showError(
        'Unable to submit monthly TQI checks',
        error?.response?.data?.message
      )
    } finally {
      setUpdating(false)
    }
  }

  return (
    <section key={month} className="space-y-6">
      {user?.can('upload-tqi') && (
        <div className="col-span-3 sm:col-span-1">
          <FileImport
            id="tqi"
            title="TQI Upload"
            endpoint="/import/tqi"
            centre={centre!.id}
            date={format(month, 'yyyy-MM-dd')}
            uploadSuccessful={() => dispatch(listTqis(centre.id))}
          />
        </div>
      )}

      <Tqis centre={centre} />
    </section>
  )
}
import { useEffect, useState } from "react"
import AuthService from "../api/auth"
import { useAppSelector } from "../hooks/useRedux"
import useTitle from "../hooks/useTitle"
import { ErrorBag } from "../types/errors"
import { classNames } from "../utils"

export default function Profile() {
  useTitle('Profile')
  const user = useAppSelector(state => state.auth.authenticatedUser)
  const [updatingProfile, setUpdatingProfile] = useState(false)
  const [updatingPassword, setUpdatingPassword] = useState(false)
  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [updatePasswordErrors, setUpdatePasswordErrors] = useState<ErrorBag>()
  const [updateProfileErrors, setUpdateProfileErrors] = useState<ErrorBag>()

  useEffect(() => {
    setName(user?.name)
    setEmail(user?.email)
  }, [user])

  const submitProfileInformation = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setUpdatingProfile(true)
    try {
      await AuthService.updateProfileInformation({ name: name!, email: email! })
    } catch (e: any) {
      setUpdateProfileErrors(new ErrorBag(e.response.data))
    } finally {
      setUpdatingProfile(false)
    }
  }

  const submitUpdatePassword = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setUpdatingPassword(true)

    try {
      await AuthService.updatePassword({
        current_password: currentPassword!,
        password: password!,
        password_confirmation: passwordConfirmation!,
      })
    } catch (e: any) {
      setUpdatePasswordErrors(new ErrorBag(e.response.data))
    } finally {
      setUpdatingPassword(false)
    }
  }

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-header font-medium leading-6 text-gray-700">Personal Information</h3>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={submitProfileInformation}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="tester-id" className="block text-sm font-medium text-gray-700">
                        Tester ID
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="tester-id"
                          id="tester-id"
                          value={user?.tester_id}
                          disabled
                          className="bg-gray-100 appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="name"
                        className={classNames(
                          "label",
                          updateProfileErrors?.get('name') ? "text-red-600" : ""
                        )}>
                        Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className={classNames(
                            "input",
                            updateProfileErrors?.get('name') ? "focus:ring-red-400 focus:border-red-400" : ""
                          )}
                          value={name}
                          onChange={e => setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="email"
                        className={classNames(
                          "label",
                          updateProfileErrors?.get('email') ? "text-red-600" : ""
                        )}
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className={classNames(
                            "input",
                            updateProfileErrors?.get('email') ? "focus:ring-red-400 focus:border-red-400" : ""
                          )}
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center btn btn--primary"
                    disabled={updatingProfile || updatingPassword}
                  >
                    {updatingProfile ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-300" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-header font-medium leading-6 text-gray-700">Update Password</h3>
              {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={submitUpdatePassword}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="current-password"
                        className={classNames(
                          "label",
                          updatePasswordErrors?.get('current_password') ? "text-red-600" : ""
                        )}
                      >
                        Current Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="current-password"
                          id="current-password"
                          className={classNames(
                            "input",
                            updatePasswordErrors?.get('current_password') ? "focus:ring-red-400 focus:border-red-400" : ""
                          )}
                          value={currentPassword}
                          onChange={e => setCurrentPassword(e.target.value)}
                          required
                        />
                        <small className="text-red-600">{updatePasswordErrors?.get('current_password')}</small>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="new-password"
                        className={classNames(
                          "label",
                          updatePasswordErrors?.get('password') ? "text-red-600" : ""
                        )}
                        >
                        New Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="new-password"
                          id="new-password"
                          className={classNames(
                            "input",
                            updatePasswordErrors?.get('password') ? "focus:ring-red-400 focus:border-red-400" : ""
                          )}
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          required
                        />
                        <small className="text-red-600">{updatePasswordErrors?.get('password')}</small>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="password-confirmation" className="block text-sm font-medium text-gray-700">
                        Confirm New Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="password-confirmation"
                          id="password-confirmation"
                          className="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm"
                          value={passwordConfirmation}
                          onChange={e => setPasswordConfirmation(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center btn btn--primary"
                    disabled={updatingPassword || updatingProfile}
                  >
                    {updatingPassword ? 'Saving...' : 'Update Password'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

import { addYears, format, getUnixTime, subYears } from "date-fns"
import { uniqueId } from "lodash"
import { useEffect, useState } from "react"
import { submitTrainingRecordsData } from "../../api/audit"
import { deleteFile, downloadFile, getFilesByType } from "../../api/files"
import useAuth from "../../hooks/useAuth"
import { useNotification } from "../../hooks/useNotification"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { getTrainingRecords } from "../../store/auditSlice"
import { TrainingRecord } from "../../types/audit"
import { Centre } from "../../types/centre"
import { File } from "../../types/file"
import { User } from "../../types/user"
import { classNames, DATE_FORMAT, formatTimestampString, stringToUnix, unixToString } from "../../utils"
import DatePicker from "../DatePicket"
import FileImport from "../FileImport"
import ImportModal from "../ImportModal"
import Select from "../Select"
import Switch from "../Switch"
import TrainingRecords from "../TrainingRecords"

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

type Props = {
  centre: Centre
  trainingRecords: Partial<TrainingRecord>[]
  setTrainingRecords: React.Dispatch<React.SetStateAction<Partial<TrainingRecord>[]>>
}

const ragOptions = [
  { id: 'green', label: 'Green' },
  { id: 'amber', label: 'Amber' },
  { id: 'red', label: 'Red' },
  { id: 'white', label: 'White' },
]

export default function Training({ centre, trainingRecords, setTrainingRecords }: Props) {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const month = useAppSelector(state => state.centres.month)
  const [updating, setUpdating] = useState(false)
  const [hasTesters, setHasTesters] = useState(true)
  const [upload, setUpload] = useState<{ endpoint: string, params?: { key: string, value: any }[] }>()
  const [showUpload, setShowUpload] = useState(false)
  const notification = useNotification()

  const [files, setFiles] = useState<File[]>([])
  useEffect(() => {
    if (MANAGEMENT_ENABLED) {
      return
    }

    getFilesByType(centre.id, 'training').then(response => setFiles(response.data.data))
  }, [centre])

  useEffect(() => {
    const testers = centre?.testers?.filter(t => t.tester_id)
    setHasTesters(testers ? testers.length > 0 : false)
  }, [centre])

  const updateTrainingRecord = (trainingRecord: Partial<TrainingRecord>, tester: string) => {
    const idx = trainingRecords.findIndex(r => r.tester_id === trainingRecord.tester_id)
    if (idx >= 0) {
      const updated = [...trainingRecords]
      updated[idx] = trainingRecord
      setTrainingRecords(updated)
    } else {
      setTrainingRecords([...trainingRecords, { ...trainingRecord, tester_id: tester }])
    }
  }

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    setUpdating(true)
    try {
      await submitTrainingRecordsData(centre.id, format(month, 'yyyy'), trainingRecords)
      dispatch(getTrainingRecords({ centre: centre.id, year: format(month, 'yyyy') }))
      notification.showSuccess(
        'Training records saved'
      )
    } catch (error: any) {
      notification.showError(
        'Unable to submit training records',
        error?.response?.data?.message
      )
    } finally {
      setUpdating(false)
    }
  }

  const uploadEvidence = (record: Partial<TrainingRecord>) => {
    setUpload({
      endpoint: `/management/training/${record.id}/evidence`
    })
    setShowUpload(true);
  }

  if (!MANAGEMENT_ENABLED) {
    return (
      <section className="space-y-6">
        <FileImport id="training" title="Training Import" endpoint="/import/training" centre={centre!.id} />
        <TrainingRecords centre={centre} />
      </section>
    )
  }

  return (
    <section key={month}>
      <form onSubmit={submit}>
        <div className="space-y-6">
          {!hasTesters ? (
            <div className="flex justify-center items-center h-64">
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Centre has no testers.
              </h2>
            </div>
          ) : (
            centre.testers?.map(tester => {
              if (!tester.tester_id || !tester.roles?.includes('tester')) {
                return <></>
              }

              const trainingRecord = trainingRecords.find(r => r.tester_id === tester.tester_id)

              return (
                <div key={tester.id} className="shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6 border-b border-grey-50 rounded-t-md">
                    <div className="flex items-end justify-between">
                      <h2 className="text-xl leading-6 font-medium text-gray-900">
                        {tester.name}
                      </h2>
                      <p className="text-sm text-gray-500">
                        {tester.tester_id}
                      </p>
                    </div>
                  </div>
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-2 gap-6">
                      <div className="col-span-2 sm:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          Type of Training
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            className="input"
                            value={trainingRecord?.type ?? ''}
                            onChange={e => updateTrainingRecord({ ...trainingRecord, type: e.target.value }, tester.tester_id!)}
                          />
                        </div>
                      </div>
                      <div className="col-span-2 sm:col-span-1">
                        <label className="block text-sm font-medium text-gray-700">
                          RAG Rating
                        </label>
                        <div className="mt-1">
                          <Select
                            options={ragOptions}
                            selected={ragOptions.find(o => o.id === trainingRecord?.rag_rating?.toLowerCase()) || { label: 'Please Select' }}
                            setSelected={option => updateTrainingRecord({ ...trainingRecord, rag_rating: option.id?.toString() }, tester.tester_id!)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-6">
                      <div className="col-span-1">
                        {/* Previous Assessment */}
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                          Annual Assessment {format(subYears(month, 1), 'yyyy')}/{format(month, 'yyyy')}
                        </h3>

                        <div className="grid grid-cols-2 gap-6">
                          <div className="col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Provider
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="input"
                                value={trainingRecord?.previous_provider ?? ''}
                                onChange={e => updateTrainingRecord({ ...trainingRecord, previous_provider: e.target.value }, tester.tester_id!)}
                              />
                            </div>
                          </div>

                          <div className="col-span-2 sm:col-span-1">
                            <label className="block text-sm font-medium text-gray-700">
                              Pass Mark
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="input"
                                value={trainingRecord?.previous_pass_mark ?? ''}
                                onChange={e => updateTrainingRecord({ ...trainingRecord, previous_pass_mark: e.target.value }, tester.tester_id!)}
                              />
                            </div>
                          </div>

                          <div className="col-span-2 sm:col-span-1">
                            <label className="block text-sm font-medium text-gray-700">
                              Assessment Date
                            </label>
                            <div className="mt-1">
                              {/* <input
                                type="text"
                                className="input"
                                value={trainingRecord?.previous_assessment ?? ''}
                                onChange={e => updateTrainingRecord({ ...trainingRecord, previous_assessment: e.target.value }, tester.tester_id!)}
                              /> */}
                              <DatePicker
                                date={trainingRecord?.previous_assessment ? stringToUnix(trainingRecord?.previous_assessment, DATE_FORMAT) : undefined}
                                onChange={date => updateTrainingRecord({ ...trainingRecord, previous_assessment: date ? unixToString(date, DATE_FORMAT) : null }, tester.tester_id!)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        {/* Current Assessment */}
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                          Annual Assessment {format(month, 'yyyy')}/{format(addYears(month, 1), 'yyyy')}
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <div className="col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Provider
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="input"
                                value={trainingRecord?.provider ?? ''}
                                onChange={e => updateTrainingRecord({ ...trainingRecord, provider: e.target.value }, tester.tester_id!)}
                              />
                            </div>
                          </div>

                          <div className="col-span-2 sm:col-span-1">
                            <label className="block text-sm font-medium text-gray-700">
                              Pass Mark
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                className="input"
                                value={trainingRecord?.pass_mark ?? ''}
                                onChange={e => updateTrainingRecord({ ...trainingRecord, pass_mark: e.target.value }, tester.tester_id!)}
                              />
                            </div>
                          </div>

                          <div className="col-span-2 sm:col-span-1">
                            <label className="block text-sm font-medium text-gray-700">
                              Assessment Date
                            </label>
                            <div className="mt-1">
                              {/* <input
                                type="text"
                                className="input"
                                value={trainingRecord?.assessment ?? ''}
                                onChange={e => updateTrainingRecord({ ...trainingRecord, assessment: e.target.value }, tester.tester_id!)}
                              /> */}
                              <DatePicker
                                date={trainingRecord?.assessment ? stringToUnix(trainingRecord?.assessment, DATE_FORMAT) : undefined}
                                onChange={date => updateTrainingRecord({ ...trainingRecord, assessment: date ? unixToString(date, DATE_FORMAT) : null }, tester.tester_id!)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="grid grid-cols-4 gap-6 items-center">
                      <div className="col-span-4 sm:col-span-2">
                        <Switch
                          label="Conducted an MOT in the past 6 months?"
                          enabled={trainingRecord?.mot_in_past_6_months ?? false}
                          setEnabled={enabled => updateTrainingRecord({ ...trainingRecord, mot_in_past_6_months: enabled }, tester.tester_id!)}
                        />
                      </div>

                      <div className="col-span-4 sm:col-span-1">
                        <Switch
                          label="Additional CPD"
                          enabled={trainingRecord?.additional_cpd ?? false}
                          setEnabled={enabled => updateTrainingRecord({ ...trainingRecord, additional_cpd: enabled }, tester.tester_id!)}
                        />
                      </div>

                      {trainingRecord?.supporting_files && trainingRecord.supporting_files.length > 0 && (
                        <div className="col-span-4">
                          <span className="block mb-1 text-sm font-medium text-gray-700">
                            Evidence
                          </span>
                          <div className="border rounded-md overflow-hidden shadow-sm">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50 font-header">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                  >
                                    Created At
                                  </th>
                                  <th scope="col" className="relative px-6 py-3">
                                    <span className="sr-only">Actions</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {trainingRecord.supporting_files.map((file, fileIdx) => (
                                  <tr key={file.id} className={fileIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {file.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatTimestampString(file.created_at)}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                      <button
                                        type="button"
                                        className="link"
                                        onClick={() => downloadFile(file)}
                                      >
                                        View
                                      </button>
                                      <button
                                        type="button"
                                        className="ml-2 link link--destructive"
                                        onClick={async () => {
                                          await deleteFile(file.centre_id, file.id)
                                          dispatch(getTrainingRecords({ centre: centre.id, year: format(month, 'yyyy') }))
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="px-4 py-3 bg-gray-50 flex justify-end sm:px-6 sm:rounded-b-md">
                    <button
                      type="button"
                      className={classNames(
                        "btn btn--tertiary btn--small",
                        !trainingRecord?.id ? "invisible" : "",
                      )}
                      disabled={!trainingRecord?.id}
                      onClick={() => trainingRecord && uploadEvidence(trainingRecord)}
                    >
                      Add Evidence
                    </button>
                  </div>
                </div>
              )
            })
          )}

          {user?.can('submit-centre-reports') && (
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center btn btn--primary"
                disabled={!hasTesters || updating}
              >
                {updating ? 'Saving...' : 'Save'}
              </button>
            </div>
          )}
        </div>
      </form>

      {upload && (
        <ImportModal
          endpoint={upload.endpoint}
          centre={centre.id}
          open={showUpload}
          setOpen={setShowUpload}
          uploadSuccessful={() => dispatch(getTrainingRecords({ centre: centre.id, year: format(month, 'yyyy') }))}
        />
      )}
    </section>
  )
}
import { Switch as HeadlessSwitch } from '@headlessui/react'
import { classNames } from '../utils'

export default function Switch({ label, enabled, setEnabled, disabled = false }: { label: string, enabled: boolean, setEnabled: (checked: boolean) => void, disabled?: boolean }) {
  return (
    <HeadlessSwitch.Group as="div" className="flex items-center">
      <HeadlessSwitch
        disabled={disabled}
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          disabled ? 'bg-gray-100' : (enabled ? 'bg-secondary-500' : 'bg-gray-200'),
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200',
            disabled ? 'bg-grey-200' : 'bg-white',
          )}
        />
      </HeadlessSwitch>
      <HeadlessSwitch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-700">{label}</span>
      </HeadlessSwitch.Label>
    </HeadlessSwitch.Group>
  )
}

import axios from "axios";
import { store } from "../store";
import { logout } from "../store/authSlice";

export const authClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8008',
  withCredentials: true,
})

authClient.interceptors.response.use(
  response => response,
  error => {
    const { auth } = store.getState()

    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      auth.authenticated
    ) {
      store.dispatch(logout())
    }

    return Promise.reject(error)
  }
)

const actions = {
  async login(email: string, password: string, remember: boolean) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/login', { email, password, remember })
  },
  logout() {
    return authClient.post('/logout')
  },
  async forgotPassword(email: string) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/forgot-password', { email })
  },
  async resetPassword(token: string, email: string, password: string, password_confirmation: string) {
    await authClient.get('/sanctum/csrf-cookie')
    return authClient.post('/reset-password', { token, email, password, password_confirmation })
  },
  getAuthUser() {
    return authClient.get('/api/user')
  },
  updateProfileInformation(payload: { name: string, email: string }) {
    return authClient.put('/user/profile-information', payload)
  },
  updatePassword(payload: { current_password: string, password: string, password_confirmation: string }) {
    return authClient.put('/user/password', payload)
  },
}

export default actions

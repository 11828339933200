import { ArrowsExpandIcon, ChevronDownIcon } from "@heroicons/react/outline"
import { useCallback, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { submitTestLogData, TestLogDataPayload } from "../../api/audit"
import useAuth from "../../hooks/useAuth"
import { useNotification } from "../../hooks/useNotification"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { getTestLogs } from "../../store/auditSlice"
import { TestLog } from "../../types/audit"
import { Centre } from "../../types/centre"
import { classNames, DATETIME_STRING_FORMAT, dateToString, formatDateString, TIMESTAMP_STRING_FORMAT } from "../../utils"
import FileImport from "../FileImport"
import Pagination from "../Pagination"

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

type Props = {
  centre: Centre
  showUpload?: boolean
  showExpand?: boolean
  perPage?: number
}

type TestLogData = {
  [id: string | number]: {
    cost?: string | null
    account_no?: string | null
  }
}

export default function TestLogs({ centre, showUpload = false, showExpand = false, perPage }: Props) {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const month = useAppSelector(state => state.centres.month)
  const testLogs = useAppSelector(state => state.audit[centre.id]?.testLogs)
  const [logs, setLogs] = useState<TestLog[]>([])
  const notification = useNotification()
  const [page, setPage] = useState(1)
  const [testLogData, setTestLogData] = useState<TestLogData>({})
  const [updating, setUpdating] = useState(false)
  const currentSort = useRef<string>()

  const dispatchTestLogs = useCallback((sort?: string) => {
    sort = sort || currentSort.current
    dispatch(getTestLogs({ centre: centre.id, month: dateToString(month ?? (new Date()).valueOf()), page, sort, per_page: perPage }))
  }, [centre.id, dispatch, month, page, perPage])

  useEffect(() => dispatchTestLogs(), [dispatchTestLogs])

  useEffect(() => {
    setLogs(testLogs?.data || [])
  }, [testLogs?.data])

  const sort = (col: string) => {
    const asc = `${col}-asc`
    const desc = currentSort.current === asc
    dispatchTestLogs(desc ? `${col}-desc` : asc)

    currentSort.current = desc ? `${col}-desc` : asc
  }

  useEffect(() => {
    setTestLogData(logs => {
      const newLogs = { ...logs }
      testLogs?.data.forEach(test => {
        newLogs[test.id] = { cost: test.cost, account_no: test.account_no, ...newLogs[test.id] }
      })

      return newLogs
    })
  }, [testLogs?.data])

  const updateLogValue = (id: string | number, key: string, value: string) => {
    setTestLogData(logs => ({ ...logs, [id]: { ...logs[id], [key]: value } }))
  }

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setUpdating(true)
    try {
      const logs = Object.keys(testLogData)
        .map(logId => ({ id: logId, ...testLogData[logId] }))
        .filter((log): log is TestLogDataPayload => {
          return log.cost !== undefined
            && log.account_no !== undefined
            && (log.cost !== null || log.account_no !== null)
        })
      await submitTestLogData(logs)
      dispatchTestLogs()
    } catch (error: any) {
      notification.showError(
        'Unable to submit test log data',
        error?.response?.data?.message
      )
    } finally {
      setUpdating(false)
    }
  }

  return (
    <section className="space-y-6">
      {showUpload && user?.can('upload-test-logs') && (
        <div className="col-span-3 sm:col-span-1">
          <FileImport
            id="test-logs"
            title="Upload"
            endpoint="/import/test-logs"
            centre={centre!.id}
            uploadSuccessful={() => dispatchTestLogs()}
          />
        </div>
      )}

      <form onSubmit={submit}>
        <div className="space-y-6">
          <div className="-mx-1 sm:overflow-x-auto">
            <div className="px-1 pb-1 align-middle inline-block min-w-full">
              <div className="shadow border-b border-gray-200 sm:overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 font-header">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Test Date/Time
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => sort('tested_at')}
                          >
                            <ChevronDownIcon
                              className={classNames(
                                'w-4 h-4 transition-transform',
                                currentSort.current?.startsWith('tested_at') ? 'text-secondary-500' : 'text-gray-300',
                                currentSort.current === 'tested_at-desc' ? 'transform -rotate-180' : ''
                              )}
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Registration
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Make
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Model
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Class
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Tester ID
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => sort('tester_id')}
                          >
                            <ChevronDownIcon
                              className={classNames(
                                'w-4 h-4 transition-transform',
                                currentSort.current?.startsWith('tester_id') ? 'text-secondary-500' : 'text-gray-300',
                                currentSort.current === 'tester_id-desc' ? 'transform -rotate-180' : ''
                              )}
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Type
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => sort('type')}
                          >
                            <ChevronDownIcon
                              className={classNames(
                                'w-4 h-4 transition-transform',
                                currentSort.current?.startsWith('type') ? 'text-secondary-500' : 'text-gray-300',
                                currentSort.current === 'type-desc' ? 'transform -rotate-180' : ''
                              )}
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Result
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => sort('result')}
                          >
                            <ChevronDownIcon
                              className={classNames(
                                'w-4 h-4 transition-transform',
                                currentSort.current?.startsWith('result') ? 'text-secondary-500' : 'text-gray-300',
                                currentSort.current === 'result-desc' ? 'transform -rotate-180' : ''
                              )}
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Reason for Abort
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Test Duration
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => sort('duration')}
                          >
                            <ChevronDownIcon
                              className={classNames(
                                'w-4 h-4 transition-transform',
                                currentSort.current?.startsWith('duration') ? 'text-secondary-500' : 'text-gray-300',
                                currentSort.current === 'duration-desc' ? 'transform -rotate-180' : ''
                              )}
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        Date/Time Contingency Test
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          Brake Test Type
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => sort('brake_test_type')}
                          >
                            <ChevronDownIcon
                              className={classNames(
                                'w-4 h-4 transition-transform',
                                currentSort.current?.startsWith('brake_test_type') ? 'text-secondary-500' : 'text-gray-300',
                                currentSort.current === 'brake_test_type-desc' ? 'transform -rotate-180' : ''
                              )}
                            />
                          </button>
                        </div>
                      </th>
                      {MANAGEMENT_ENABLED && (
                        <>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                          >
                            Cost (£)
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                          >
                            Account No.
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {(testLogs && testLogs.data.length > 0) ? (
                      testLogs?.data.map((test, testIdx) => (
                        <tr key={test.id} className={testIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {formatDateString(test.tested_at, DATETIME_STRING_FORMAT)}
                            {/* <small className="block text-xs font-base text-gray-500">{test.number}</small> */}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.vehicle.registration}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.vehicle.model?.brand?.name || 'unknown'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.vehicle.model?.name?.replaceAll(/[="]/gi, '') || 'unknown'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.vehicle.class}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.tester_id}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.type}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.result}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.reason_for_aborting}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.formatted_duration}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.meta?.ct_test_recorded_at}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{test.brake_test_type}</td>
                          {MANAGEMENT_ENABLED && (
                            <>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                                <input
                                  type="text" className="input"
                                  value={testLogData[test.id]?.cost || undefined} onChange={e => updateLogValue(test.id, 'cost', e.target.value)}
                                />
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                                <input
                                  type="text" className="input"
                                  value={testLogData[test.id]?.account_no || undefined} onChange={e => updateLogValue(test.id, 'account_no', e.target.value)}
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr className="bg-white">
                        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-300" colSpan={16}>
                          No test logs
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="-mt-4 flex justify-end items-center">
            <div className="mr-auto">
              <Pagination page={page} setPage={setPage} numPages={testLogs?.pagination.last_page || 1} />
            </div>
            {showExpand && (
              <Link
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                to={`/centres/${centre?.id}/test-logs`}
                className="inline-flex justify-center btn btn--tertiary mr-4"
              >
                Expand <ArrowsExpandIcon className="ml-2 w-5 h-5" />
              </Link>
            )}
            {user?.can('submit-centre-reports') && (
              <button
                type="submit"
                className="inline-flex justify-center btn btn--primary"
                disabled={!testLogs || testLogs.data.length === 0 || updating}
              >
                {updating ? 'Saving...' : 'Save'}
              </button>
            )}
          </div>
        </div>
      </form>
    </section>
  )
}
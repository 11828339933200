import { UserIcon } from "@heroicons/react/solid";
import { range } from "lodash";
import { classNames } from "../utils";

type Props = {
  testers?: number
  ratings?: {
    green: number
    amber: number
    red: number
    white: number
  }
}

export default function TesterCard(props: Props) {
  return (
    <div
      className={classNames(
        "h-40 bg-white overflow-hidden shadow-md rounded-lg transition-shadow duration-300"
      )}
    >
      <div className="h-full flex flex-col p-4">
        <h5 className="mb-6 text-xl font-header">Testers</h5>
        <div className="mt-auto flex flex-wrap">
          {range(0, props.ratings?.green).map(() => (
            <UserIcon className="w-10 h-10 text-green-600" />
          ))}
          {range(0, props.ratings?.amber).map(() => (
            <UserIcon className="w-10 h-10 text-yellow-600" />
          ))}
          {range(0, props.ratings?.red).map(() => (
            <UserIcon className="w-10 h-10 text-red-600" />
          ))}
          {range(0, props.ratings?.white).map(() => (
            <UserIcon className="w-10 h-10 text-black" />
          ))}
        </div>
      </div>
    </div>
  )
}
import { useEffect } from "react";
import { listPermissions } from "../store/roleSlice";
import { Permission } from "../types/permission";
import { useAppDispatch, useAppSelector } from "./useRedux";

export default function usePermissions() {
  const dispatch = useAppDispatch()
  const permissions: Permission[] = useAppSelector(state => state.roles.permissions)

  useEffect(() => { dispatch(listPermissions()) }, [dispatch])

  return permissions
}
import React, { useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router"
import useTitle from "../hooks/useTitle"
import ApiService from "../api/api"
import { useDispatch } from "react-redux"
import { listUsers, putUser } from "../store/userSlice"
import useUser from "../hooks/useUser"
import useRoles from "../hooks/useRoles"
import Switch from "../components/Switch"
import ConfirmationModal from "../components/ConfirmationModal"
import { useNotification } from "../hooks/useNotification"
import { ErrorBag } from "../types/errors"
import useCentres from "../hooks/useCentres"
import useUsers from "../hooks/useUsers"

export default function EditUser(props: RouteComponentProps<{ id: string }>) {
  const history = useHistory()
  const dispatch = useDispatch()
  const notification = useNotification()
  const user = useUser(parseInt(props.match.params.id))
  const centres = useCentres()
  const roles = useRoles()
  useTitle(user ? 'Edit User' : 'Create User')

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState(user?.name || '')
  const [email, setEmail] = useState(user?.email || '')
  const [testerId, setTesterId] = useState(user?.tester_id || '')
  const [password, setPassword] = useState<string>()
  const [password_confirmation, setPasswordConfirmation] = useState<string>()
  const [userCentres, setUserCentres] = useState<(string | number)[]>(user?.centres || [])
  const [userRoles, setUserRoles] = useState(user?.roles || [])
  const [errors, setErrors] = useState<ErrorBag>()

  const payload = () => {
    return { name, email, tester_id: testerId, centres: userCentres, roles: userRoles, password, password_confirmation }
  }


  const createUser = async () => {
    return await ApiService.createUser(payload())
  }

  const updateUser = async () => {
    return await ApiService.updateUser(user!.id, payload())
  }

  const deleteUser = async () => {
    setSaving(true)

    try {
      await ApiService.deleteUser(user!.id)
      notification.showSuccess(
        'Successfully Disabled',
        `${name} has been successfully disabled.`
      )
      await dispatch(listUsers({}));
      history.replace('/users')
    } catch (error: any) {
      notification.showError(
        'Failed to Disable',
        `${name} could not be disabled at this time.`
      )
    } finally {
      setSaving(false)
    }
  }

  const saveUser = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)
    setErrors(undefined)

    try {
      const response = user ? await updateUser() : await createUser()

      notification.showSuccess(
        'Successfully Saved',
        `${name} has been successfully saved.`
      )

      dispatch(putUser(response.data.data))

      if (response.status === 201) {
        history.replace(`/users/${response.data.data.id}/edit`)
      }
    } catch (e: any) {
      setErrors(new ErrorBag(e.response.data))
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-700">{user?.name}</h3>
              <h5 className="mt-1 text-sm text-gray-500">{user?.tester_id}</h5>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={saveUser}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-t-md">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="name" className="label">
                        Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          className="input"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="email" className="label">
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          className="input"
                          autoComplete="off"
                        />
                      </div>
                      <small className="text-red-600">{errors?.get('email')}</small>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="tester-id" className="label">
                        Tester ID
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="tester-id"
                          id="tester-id"
                          value={testerId}
                          onChange={e => setTesterId(e.target.value)}
                          className="input"
                        />
                      </div>
                      <small className="text-red-600">{errors?.get('tester_id')}</small>
                    </div>
                  </div>
                  {!(userRoles.length === 1 && userRoles.includes('tester')) && (
                    <>
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label htmlFor="tester-id" className="label">
                            Password
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="password"
                              id="password"
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                              className="input"
                              required={!user}
                              autoComplete="new-password"
                            />
                          </div>
                          <small className="text-red-600">{errors?.get('password')}</small>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label htmlFor="tester-id" className="label">
                            Password Confirmation
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="password"
                              id="password"
                              value={password_confirmation}
                              onChange={e => setPasswordConfirmation(e.target.value)}
                              className="input"
                              required={!user || !!password}
                              autoComplete="new-password"
                            />
                          </div>
                          <small className="text-red-600">{errors?.get('password_confirmation')}</small>
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    <label className="label">
                      Assigned Centres
                    </label>
                    <div className="mt-2 grid grid-cols-3 gap-6">
                      {centres.map(centre => {
                        const isAdmin = userRoles.includes('admin')

                        return (
                          <div className="col-span-3 sm:col-span-1">
                            <Switch
                              disabled={isAdmin}
                              label={centre.name.replace('-', ' ')}
                              enabled={userCentres.includes(centre.id)}
                              setEnabled={checked => {
                                if (checked) {
                                  setUserCentres(userCentres => {
                                    const updated = [...userCentres]
                                    updated.push(centre.id)
                                    return updated
                                  })
                                } else {
                                  setUserCentres(userCentres => {
                                    const updated = [...userCentres]
                                    updated.splice(updated.indexOf(centre.id), 1)
                                    return updated
                                  })
                                }
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div>
                    <label className="label">
                      Roles
                    </label>
                    <div className="mt-2 grid grid-cols-3 gap-6">
                      {roles.map(role => {
                        const isAdmin = userRoles.includes('admin')

                        return (
                          <div className="col-span-3 sm:col-span-1 capitalize">
                            <Switch
                              disabled={isAdmin && role.name !== 'admin'}
                              label={role.name.replace('-', ' ')}
                              enabled={userRoles && userRoles.includes(role.name)}
                              setEnabled={checked => {
                                if (checked) {
                                  setUserRoles(userRoles => {
                                    const newRoles = [...userRoles]
                                    newRoles.push(role.name)
                                    return newRoles
                                  })
                                } else {
                                  setUserRoles(userRoles => {
                                    const newRoles = [...userRoles]
                                    newRoles.splice(newRoles.indexOf(role.name), 1)
                                    return newRoles
                                  })
                                }
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>

                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-md">
                  <button
                    type="button"
                    className="mr-4 inline-flex justify-center btn btn--tertiary"
                    disabled={saving}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  {user && (
                    <button
                      type="button"
                      className="mr-4 inline-flex justify-center btn btn--destructive"
                      disabled={saving}
                      onClick={() => setConfirmOpen(true)}
                    >
                      Disable
                    </button>
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center btn btn--primary"
                    disabled={saving}
                  >
                    {saving ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ConfirmationModal
        action="Disable"
        title={user?.name}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteUser}
      />
    </>
  )
}

import { Link } from "react-router-dom";
import TestLogs from "../components/ManagementDataCapture/TestLogs";
import useCentre from "../hooks/useCentre";
import useTitle from "../hooks/useTitle";

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

export default function TestLogsPage({ id }: { id: string }) {
  const centre = useCentre(+id)
  useTitle('Test Logs', centre?.name, true, true)

  return (
    <>
      <div className="flex justify-end">
        <Link to={`/centres/${centre?.id}`} className="mr-4 btn btn--small btn--tertiary">
          Dashboard
        </Link>
        <Link to={`/centres/${centre?.id}/capture`} className="btn btn--small btn--tertiary">
          Reporting
        </Link>
      </div>

      <div className="py-4 lg:py-6">
        <TestLogs centre={centre!} perPage={MANAGEMENT_ENABLED ? 200 : 2000} />
      </div>
    </>
  )
}
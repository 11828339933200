import { createSelector } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getRole } from "../store/roleSlice";
import { useAppDispatch } from "./useRedux";

const selectRoleSelector = createSelector(
  (state: RootState) => state.roles.roles,
  (_: RootState, id: number) => id,
  (roles, id) => roles.find(role => role.id === id)
)

export default function useRole(id: number) {
  const dispatch = useAppDispatch()
  const role = useSelector((state: RootState) => selectRoleSelector(state, id))

  useEffect(() => {
    if (id) dispatch(getRole(id))
  }, [dispatch, id])

  return role
}
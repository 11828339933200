import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import useAuth from "../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import useTitle from "../hooks/useTitle";
import { getIpAddresses, getTests, getTestTimes } from "../store/regionalSlice";

export default function Dashboard() {
  useTitle('Dashboard', undefined, true)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const user = useAuth()
  const month = useAppSelector(state => state.centres.month)
  const tests = useAppSelector(state => state.regional.tests ?? [])
  const testTimes = useAppSelector(state => state.regional.testTimes ?? [])
  const ipAddresses = useAppSelector(state => state.regional.ipAddresses ?? [])
  const graphClamp = 5;

  if (!user || !user.centres || user.centres.length < 1) {
    history.replace('/centres')
  }

  useEffect(() => {
    dispatch(getTests())
    dispatch(getTestTimes())
    dispatch(getIpAddresses())
  }, [dispatch, month])

  const handleBarChartClick = (data: any) => {
    const id = data.activePayload[0]?.payload.id
    history.push(`/centres/${id}`)
  }

  return (
    <div className="py-4 lg:py-6 grid gap-4 md:gap-6">
      <div className="bg-white p-4 rounded-lg shadow-md col-span-full lg:col-span-2 h-full">
        <ResponsiveContainer width="99.9%" height={400} debounce={1}>
          <BarChart data={tests} onClick={handleBarChartClick}>
            <CartesianGrid strokeDasharray="6" />
            <XAxis dataKey="name" hide />
            <YAxis domain={[0, (dataMax: number) => (Math.ceil(dataMax / graphClamp) * graphClamp)]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#0073AD" name="Tests" />
            <Bar dataKey="aborted" fill="#B91C1C" name="Aborted Tests" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md col-span-full lg:col-span-2 h-full">
        <ResponsiveContainer width="99.9%" height={400} debounce={1}>
          <BarChart data={testTimes} onClick={handleBarChartClick}>
            <CartesianGrid strokeDasharray="6" />
            <XAxis dataKey="name" hide />
            <YAxis domain={[0, (dataMax: number) => (Math.ceil(dataMax / graphClamp) * graphClamp)]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="short" fill="#0087CC" name="Short Tests" />
            <Bar dataKey="long" fill="#005F8E" name="Long Tests" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md col-span-full lg:col-span-2 h-full">
        <ResponsiveContainer width="99.9%" height={400} debounce={1}>
          <BarChart data={ipAddresses} onClick={handleBarChartClick}>
            <CartesianGrid strokeDasharray="6" />
            <XAxis dataKey="name" hide />
            <YAxis domain={[0, (dataMax: number) => (Math.ceil(dataMax / graphClamp) * graphClamp)]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#0073AD" name="IP Addresses Used" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* <div className="col-span-full flex justify-around">
        {user?.centres?.map(centre => (
          <div key={centre}>
            <h2>{centre}</h2>
            <div className="mt-6 shadow rounded-md bg-gray-800 text-green-200 p-4">
              <pre>{JSON.stringify(stats[centre], undefined, 2)}</pre>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
}
import useAuth from "../../hooks/useAuth"
import { Centre } from "../../types/centre"
import FileImport from "../FileImport"

export default function Imports({ centre }: { centre: Centre }) {
  const user = useAuth()

  return (
    <section className="space-y-6">
      {!centre.isParent && user?.can('upload-checklist') && (
        <div className="col-span-3 sm:col-span-1">
          <FileImport id="auditor-checklist" title="Auditor Visit Checklist" endpoint="/import/checklist" centre={centre!.id} />
        </div>
      )}

      {!centre.isParent && user?.can('upload-dealer-report') && (
        <div className="col-span-3 sm:col-span-1">
          <FileImport id="dealer-report" title="Monthly Report Checklist" endpoint="/import/dealer-report" centre={centre!.id} />
        </div>
      )}
    </section>
  )
}
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkAPI } from ".";
import AuthService from "../api/auth";
import { Auth, User } from "../types/auth";

const initialState: Auth = {
  authenticated: false
}

export const getAuthenticatedUser = createAsyncThunk<User, void, ThunkAPI>(
  'auth/getAuthenticatedUser',
  async () => {
    return (await AuthService.getAuthUser()).data
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload
    },
    setAuthenticatedUser: (state, action: PayloadAction<User>) => {
      state.authenticatedUser = action.payload
    },
    logout: () => {
      return initialState
    },
  },
  extraReducers: builder => {
    builder.addCase(getAuthenticatedUser.fulfilled, (state, action) => {
      state.authenticatedUser = action.payload
    })
  },
})

export const { setAuthenticated, logout } = authSlice.actions

export default authSlice.reducer

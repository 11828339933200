import { useHistory, generatePath, Link } from 'react-router-dom'
import { addMonths, format, isThisMonth, subMonths } from "date-fns"
import { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import FileRepository from "../components/FileRepository"
import CalibrationRecords from "../components/ManagementDataCapture/CalibrationRecords"
import Exports from "../components/ManagementDataCapture/Exports"
import Imports from "../components/ManagementDataCapture/Imports"
import Meeting from "../components/ManagementDataCapture/Meeting"
import MonthlyReport from "../components/ManagementDataCapture/MonthlyReport"
import QualityControl from "../components/ManagementDataCapture/QualityControl"
import TestLogs from "../components/ManagementDataCapture/TestLogs"
import Tqi from "../components/ManagementDataCapture/Tqi"
import Training from "../components/ManagementDataCapture/Training"
import NotFound from "../components/NotFound"
import useAuth from "../hooks/useAuth"
import useCentre from "../hooks/useCentre"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import useTitle from "../hooks/useTitle"
import { getCalibrationRecords, getMeetings, getQualityControlChecks, getTqiChecks, getTrainingRecords, setTab } from "../store/auditSlice"
import { CalibrationRecord, QualityControlCheck, TqiCheck, TrainingRecord } from "../types/audit"
import { classNames, dateToMonth, dateToString, stringToMonth } from "../utils"
import MonthlyChecks from '../components/ManagementDataCapture/MonthlyChecks'
import AecMasterReport from '../components/ManagementDataCapture/AecMasterReport'
import { update } from 'lodash'

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

type Item = {
  id: string
  name: string
  type: string
  hasChildren?: boolean
}

const report: Item = { id: 'report', name: 'Mailer', type: 'report' }

const managementSections = ['meeting', 'files', 'monthly-checks'];
const defaultSections = [
  { id: 'report', name: 'Mailer', type: 'report' },
  { id: 'imports', name: 'Imports', type: 'imports' },
  { id: 'files', name: 'File Repository', type: 'files' },
  { id: 'meeting', name: 'Meeting', type: 'meeting' },
  { id: 'quality-control', name: 'Quality Control', type: 'quality-control' },
  { id: 'calibration', name: 'Calibration', type: 'calibration' },
  { id: 'tqi', name: 'TQI', type: 'tqi' },
  { id: 'training', name: 'Training', type: 'training' },
  { id: 'test-logs', name: 'Test Logs', type: 'test-logs' },
  { id: 'monthly-checks', name: 'Site Manager Monthly Checks', type: 'monthly-checks' },
  { id: 'exports', name: 'Exports', type: 'exports' },
  { id: 'aec-master', name: 'AEC Master', type: 'aec-master' },
].filter(s => MANAGEMENT_ENABLED ? true : !managementSections.includes(s.id))

export default function ManagementDataCapture(props: RouteComponentProps<{ id: string }>) {
  const tab = useAppSelector(state => state.audit.tab)
  const user = useAuth()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const month = useAppSelector(state => state.centres.month)
  const centreState = useAppSelector(state => state.centres.centre)
  const isCurrentMonth = isThisMonth(addMonths(month, 1))
  const centre = useCentre(+props.match.params.id)
  useTitle(centre?.name || 'Centre', 'Management Report', true, true)
  let sections = defaultSections;

  if (! user?.can('generate-report')) {
    sections = sections.filter(i => i.id !== 'report')
  }

  if (centre?.isParent) {
    sections = sections.filter(i => i.id === 'aec-master')
  } else {
    sections = sections.filter(i => i.id !== 'aec-master')
  }

  const [current, setCurrent] = useState<Item>(sections.find(s => s.id === tab) || ((user?.can('generate-report') || centre?.isParent) ? report : sections[0]))

  const updateCurrent = (item: Item) => {
    setCurrent(item)
    dispatch(setTab(item.id.toString()))
  }

  if (!sections.some(section => section.id === current.id)) {
    updateCurrent(sections[0]);
  }

  useEffect(() => {
    const id = +props.match.params.id
    if (centreState && id !== (centreState && +centreState)) {
      history.replace(generatePath(props.match.path, { id: centreState }))
    }
  }, [centreState, history, props.match.params.id, props.match.path])

  useEffect(() => {
    const monthString = dateToString(month ?? (new Date()).valueOf())
    if (centre?.id) {
      dispatch(getMeetings({ centre: centre.id, month: monthString }))
      dispatch(getQualityControlChecks({ centre: centre.id, month: monthString }))
      dispatch(getCalibrationRecords({ centre: centre.id }))
      dispatch(getTqiChecks({ centre: centre.id, month: monthString }))
      dispatch(getTrainingRecords({ centre: centre.id, year: format(month, 'yyyy') }))
    }
  }, [centre, dispatch, month])

  // Meeting Data
  const [agendaItems, setAgendaItems] = useState<{ name: string, completed: boolean }[]>([{ name: '', completed: false }])
  const [attendees, setAttendees] = useState<string[]>([''])
  const meeting = useAppSelector(state => {
    if (centre?.id) {
      return state.audit[centre.id]?.meetings?.find(m => m.meeting_at && stringToMonth(m.meeting_at) === dateToMonth(month))
    }
  })
  useEffect(() => {
    setAgendaItems(meeting?.items || [{ name: '', completed: false }])
    setAttendees(meeting?.attendees || [''])
  }, [meeting])

  // Quality Control Data
  const [checks, setChecks] = useState<Partial<QualityControlCheck>[]>([])
  const [deleteChecks, setDeleteChecks] = useState<(string | number)[]>([])
  const qualityControlChecks = useAppSelector(state => {
    if (centre?.id) {
      return state.audit[centre.id]?.qualityControlChecks
    }
  })
  useEffect(() => { setChecks(qualityControlChecks ?? []) }, [qualityControlChecks])

  // Calibration Records
  const [calibrationRecords, setCalibrationRecords] = useState<Partial<CalibrationRecord>[]>([])
  const existingCalibrationRecords = useAppSelector(state => {
    if (centre?.id) {
      return state.audit[centre.id]?.calibrationRecords
    }
  })
  useEffect(() => {
    if (existingCalibrationRecords && existingCalibrationRecords.length > 0) {
      setCalibrationRecords(existingCalibrationRecords)
    } else {
      setCalibrationRecords([])
    }
  }, [existingCalibrationRecords])

  // Tqi
  const [tqiChecks, setTqiChecks] = useState<Partial<TqiCheck>[]>([])
  const existingTqiChecks = useAppSelector(state => {
    if (centre?.id) {
      return state.audit[centre.id]?.tqiChecks
    }
  })
  useEffect(() => { setTqiChecks(existingTqiChecks ?? []) }, [existingTqiChecks])

  // Training
  const [trainingRecords, setTrainingRecords] = useState<Partial<TrainingRecord>[]>([])
  const existingTrainingRecords = useAppSelector(state => {
    if (centre?.id) {
      return state.audit[centre.id]?.training
    }
  })
  useEffect(() => { setTrainingRecords(existingTrainingRecords ?? []) }, [existingTrainingRecords])

  if (!centre) {
    return <NotFound />
  }

  const renderSection = () => {
    switch (current.type) {
      case 'meeting':
        return <Meeting {...{ centre, meeting, agendaItems, attendees, setAgendaItems, setAttendees }} readonly={!isCurrentMonth} />
      case 'quality-control':
        return <QualityControl centre={centre} {...{ checks, setChecks, deleteChecks, setDeleteChecks }} />
      case 'calibration':
        return <CalibrationRecords centre={centre} {...{ calibrationRecords, setCalibrationRecords }} />
      case 'tqi':
        return <Tqi {...{ centre, tqiChecks, setTqiChecks }} />
      case 'monthly-checks':
        return <MonthlyChecks {...{ centre, tqiChecks, setTqiChecks }} />
      case 'training':
        return <Training {...{ centre, trainingRecords, setTrainingRecords }} />
      case 'test-logs':
        return <TestLogs centre={centre} showUpload showExpand />
      case 'report':
        return <MonthlyReport centre={centre} />
      case 'files':
        return <FileRepository centre={centre} />
      case 'imports':
        return <Imports centre={centre} />
      case 'exports':
        return <Exports centre={centre} />
      case 'aec-master':
        return <AecMasterReport centre={centre} />
    }
  }

  return (
    <>
      <div className="flex justify-end">
        {!centre.isParent && <Link to={`/centres/${centre?.id}`} className="mr-4 btn btn--small btn--tertiary">
          Dashboard
        </Link>
        }
        <Link to={`/centres/${centre?.id}/edit`} className="btn btn--small btn--tertiary">
          Edit
        </Link>
      </div>

      <div className="py-4 lg:py-6">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="space-y-2">
              {sections.map(item => {
                const isCurrent: boolean = item.id === current.id
                return (
                  <button
                    key={item.id}
                    onClick={() => updateCurrent(item)}
                    className={classNames(
                      isCurrent
                        ? 'bg-gray-100 text-secondary-500 hover:bg-gray-50'
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100',
                      'w-full group rounded-md px-3 py-2 flex items-center text-sm font-medium transition-all'
                    )}
                    aria-current={isCurrent ? 'page' : undefined}
                  >
                    <span className="truncate">{item.name}</span>
                  </button>
                )
              })}
            </nav>
          </aside>

          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            {renderSection()}
          </div>

          {/* <div className="mt-6 col-span-full shadow rounded-md bg-gray-800 text-green-200 p-4">
          <pre>{JSON.stringify(checks, undefined, 2)}</pre>
        </div> */}
        </div>
      </div>

    </>
  )
}

import { useEffect, useState } from "react"
import useCentres from "../hooks/useCentres"
import { useAppDispatch, useAppSelector } from "../hooks/useRedux"
import { setCentre } from "../store/centreSlice"
import Select, { Option } from "./Select"

type Props = {
  updateState?: boolean
  onChange?: (selected?: string | number) => void
}

const unselected = { label: 'Please Select' }

export default function CentreSelect({ updateState = false, onChange }: Props) {
  const dispatch = useAppDispatch()
  const centres = useCentres()
  const state = useAppSelector(state => state.centres.centre)
  const [selectedOption, setSelectedOption] = useState<Option>(unselected)
  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    const options = centres.map(centre => (
      { id: centre.id, label: centre.name, desc: centre.site_number }
    ))

    setOptions(options)
  }, [centres])

  useEffect(() => {
    const found = options.find(c => (c.id && +c.id) === (state && +state))
    if (found) {
      setSelectedOption(found)
    }
  }, [options, state])

  return (
    <Select
      options={options}
      selected={selectedOption}
      setSelected={selected => {
        setSelectedOption(selected)

        if (updateState) {
          dispatch(setCentre(selected.id))
        }

        if (onChange) {
          onChange(selected.id)
        }
      }}
    />
  )
}
import React, { useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router"
import useTitle from "../hooks/useTitle"
import ApiService from "../api/api"
import { useDispatch } from "react-redux"
import { putBrand } from "../store/brandSlice"
import useBrand from "../hooks/useBrand"
import ConfirmationModal from "../components/ConfirmationModal"
import { useNotification } from "../hooks/useNotification"
import { ErrorBag } from "../types/errors"

export default function EditBrand(props: RouteComponentProps<{ id: string }>) {
  const history = useHistory()
  const dispatch = useDispatch()
  const notification = useNotification()
  const brand = useBrand(parseInt(props.match.params.id))
  useTitle(brand ? 'Edit Brand' : 'Create Brand')

  // TODO: if no brand found, route to or return 404 component.

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [name, setName] = useState(brand?.name || '')
  const [errors, setErrors] = useState<ErrorBag>()

  const payload = () => ({ name })

  const createBrand = async () => {
    return await ApiService.createBrand(payload())
  }

  const updateBrand = async () => {
    return await ApiService.updateBrand(brand!.id, payload())
  }

  const deleteBrand = async () => {
    setSaving(true)

    try {
      ApiService.deleteBrand(brand!.id)
      notification.showSuccess(
        'Successfully Deleted',
        `Brand ${name} has been successfully deleted.`
      )
      history.replace('/brands')
    } catch (error: any) {
      notification.showError(
        'Failed to Delete',
        `Brand ${name} could not be deleted at this time.`
      )
    } finally {
      setSaving(false)
    }
  }

  const saveBrand = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSaving(true)
    setErrors(undefined)

    try {
      const response = brand ? await updateBrand() : await createBrand()

      notification.showSuccess(
        'Successfully Saved',
        `Brand ${name} has been successfully saved.`
      )

      dispatch(putBrand(response.data.data))

      if (response.status === 201) {
        history.replace(`/brands/${response.data.data.id}/edit`)
      }
    } catch (e: any) {
      setErrors(new ErrorBag(e.response.data))
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-700">Brand Details</h3>
              <h5 className="mt-1 text-sm text-gray-500">{brand?.name}</h5>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={saveBrand}>
              <div className="shadow sm:rounded-md">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6 sm:rounded-t-md">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="name" className="label">
                        Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          onChange={e => setName(e.target.value)}
                          className="input"
                          required
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 sm:rounded-b-md">
                  <button
                    type="button"
                    className="mr-4 inline-flex justify-center btn btn--tertiary"
                    disabled={saving}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  {brand && (
                    <button
                      type="button"
                      className="mr-4 inline-flex justify-center btn btn--destructive"
                      disabled={saving}
                      // onClick={deleteBrand}
                      onClick={() => setConfirmOpen(true)}
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="submit"
                    className="inline-flex justify-center btn btn--primary"
                    disabled={saving}
                  >
                    {saving ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-300" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-header font-medium leading-6 text-gray-700">Models</h3>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  {(brand?.models && brand.models.length > 0) ? (
                    brand.models.map(model => (
                      <div key={model.id} className="col-span-3 sm:col-span-2">
                        <input
                          type="text"
                          value={model.name}
                          readOnly
                          disabled
                          className="input"
                        />
                      </div>
                    ))
                  ) : (
                    <div className="col-span-3 sm:col-span-2 text-sm text-gray-300">
                      No models. Models are created during test logs import.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        title={brand?.name}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteBrand}
      />
    </>
  )
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkAPI } from ".";
import { File } from "../types/file";
import ApiService from "../api/api";
import { getFiles } from "../api/files";

type State = {
  loading: Boolean
  lastUpdated?: number
  files: File[]
}

const initialState: State = {
  loading: false,
  files: [],
}

export const listFiles = createAsyncThunk<File[], { centre: string | number, search?: string }, ThunkAPI>(
  'files/list',
  async ({ centre, search }, thunkAPI) => {
    return (await getFiles(centre, search)).data.data
  },
  {
    condition: (_, thunkAPI) => {
      return true
    }
  }
)

export const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    putFile: (state, action: PayloadAction<File>) => {
      const idx = state.files.findIndex(c => c.id === action.payload.id)
      const file = { ...action.payload, fetched: true }
      if (idx !== -1) {
        state.files[idx] = file
      } else {
        state.files.push(file)
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(listFiles.pending, (state, _) => {
      state.loading = true
    })
    builder.addCase(listFiles.fulfilled, (state, action) => {
      state.files = action.payload
      state.lastUpdated = Date.now()
      state.loading = false
    })
    builder.addCase(listFiles.rejected, (state, _) => {
      state.loading = false
    })
  }
})

export const { putFile } = fileSlice.actions

export default fileSlice.reducer

import { addYears, format, getUnixTime, subYears } from "date-fns"
import { uniqueId } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { submitTrainingRecordsData } from "../../api/audit"
import { deleteFile, downloadFile, getFilesByType } from "../../api/files"
import useAuth from "../../hooks/useAuth"
import { useNotification } from "../../hooks/useNotification"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux"
import { getTrainingRecords } from "../../store/auditSlice"
import { TrainingRecord } from "../../types/audit"
import { Centre } from "../../types/centre"
import { File } from "../../types/file"
import { User } from "../../types/user"
import { classNames, DATE_FORMAT, formatTimestampString, stringToUnix, unixToString } from "../../utils"
import ConfirmationModal from "../ConfirmationModal"
import DatePicker from "../DatePicket"
import FileImport from "../FileImport"
import ImportModal from "../ImportModal"
import Select from "../Select"
import Switch from "../Switch"
import TrainingRecords from "../TrainingRecords"

const MANAGEMENT_ENABLED = process.env.REACT_APP_MANAGEMENT_ENABLED === 'true'

type Props = {
  centre: Centre
}


export default function AecMasterReport({ centre }: Props) {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const month = useAppSelector(state => state.centres.month)
  const [updating, setUpdating] = useState(false)
  const [hasTesters, setHasTesters] = useState(true)
  const [upload, setUpload] = useState<{ endpoint: string, params?: { key: string, value: any }[] }>()
  const [showUpload, setShowUpload] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [currentFile, setCurrentFile] = useState<File>()
  const notification = useNotification()

  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    getFilesByType(centre.id, 'aec-master').then(response => setFiles(response.data.data))
  }, [centre])

  useEffect(() => {
    const testers = centre?.testers?.filter(t => t.tester_id)
    setHasTesters(testers ? testers.length > 0 : false)
  }, [centre])

  const dispatchDeleteFile = useCallback(async (fileId) => {
    try {
      await deleteFile(centre.id, fileId)
      getFilesByType(centre.id, 'aec-master').then(response => setFiles(response.data.data))
      notification.showSuccess('File Deleted');
    } finally {
    }
  }, [centre, notification])

  return (
    <section className="space-y-6">
      {centre.isParent && user?.can('upload-aec-master') && (
        <div className="col-span-3 sm:col-span-1">
          <FileImport
            id="aec-report"
            title="AEC Master Report"
            endpoint="/import/aec-master-report"
            centre={centre!.id}
            uploadSuccessful={() => getFilesByType(centre.id, 'aec-master').then(response => setFiles(response.data.data))}
          />
        </div>
      )}
      <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 font-header">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
              >
                Uploaded For
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {files.length > 0 ? files.map((file, fileIdx) => (
              <tr key={file.id} className={fileIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {file.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{file.date_for ? formatTimestampString(file.date_for, 'MMMM yyyy') : '-'}</td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    type="button"
                    className="link"
                    onClick={() => downloadFile(file)}
                  >
                    View
                  </button>
                  {user?.can('delete-centres') && (
                    <button
                      className="ml-2 link link--destructive"
                      onClick={() => {
                        setCurrentFile(file)
                        setConfirmOpen(true)
                      }}
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            )) : (
              <tr className="bg-white">
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-300" colSpan={4}>
                  No files.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ConfirmationModal
        title={currentFile?.name}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => {
          if (currentFile) {
            dispatchDeleteFile(currentFile.id)
          }
        }}
      />
    </section>
  )
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { ThunkAPI } from ".";
import { apiClient } from "../api/api";

type Test = {
  id: string
  name: string
  value: number
  aborted: number
}

type TestTimes = {
  id: string
  name: string
  short: number
  long: number
}

type IpAddresses = {
  id: string
  name: string
  value: number
}

type State = {
  tests: Test[]
  testTimes: TestTimes[]
  ipAddresses: IpAddresses[]
}

const initialState: State = {
  tests: [],
  testTimes: [],
  ipAddresses: [],
}

export const getTests = createAsyncThunk<Test[], void, ThunkAPI>(
  'regional/stats/test',
  async (_, thunkAPI) => {
    const { centres } = thunkAPI.getState()
    const month = format((centres.month ?? new Date()), 'yyyy-MM-dd')

    return (
      await apiClient.get('/regional/stats/test', { params: { month } })
    ).data.data
  }
)

export const getTestTimes = createAsyncThunk<TestTimes[], void, ThunkAPI>(
  'regional/stats/test-times',
  async (_, thunkAPI) => {
    const { centres } = thunkAPI.getState()
    const month = format((centres.month ?? new Date()), 'yyyy-MM-dd')

    return (
      await apiClient.get('/regional/stats/test-times', { params: { month } })
    ).data.data
  }
)

export const getIpAddresses = createAsyncThunk<IpAddresses[], void, ThunkAPI>(
  'regional/stats/ip-addresses',
  async (_, thunkAPI) => {
    const { centres } = thunkAPI.getState()
    const month = format((centres.month ?? new Date()), 'yyyy-MM-dd')

    return (
      await apiClient.get('/regional/stats/ip-addresses', { params: { month } })
    ).data.data
  }
)

export const regionalSlice = createSlice({
  name: 'regional',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getTests.fulfilled, (state, action) => {
      state.tests = action.payload
    })
    builder.addCase(getTestTimes.fulfilled, (state, action) => {
      state.testTimes = action.payload
    })
    builder.addCase(getIpAddresses.fulfilled, (state, action) => {
      state.ipAddresses = action.payload
    })
  }
})

export default regionalSlice.reducer